import React from "reactn";

import getLogger, { LogGroup } from "js/core/logger";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

const logger = getLogger(LogGroup.AUTH);

const { Provider, Consumer } = React.createContext();

/**
 * Client-side check if the user is admin. We need this additional check to prevent
 * non-admin users from seeing the client-side admin UI, even though the server will
 * 403 any attempt to use it.
 */
class AdminChecker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        (async () => {
            try {
                const idToken = await this.props.firebaseUser.getIdToken();
                const response = await fetch("/admin/availableRoutes", {
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${idToken}`
                    },
                });
                if (!response.ok) {
                    // Not admin
                    window.location = "/";
                    return;
                }

                this.availableRoutes = await response.json();

                this.setState({ isLoading: false });
            } catch (err) {
                logger.error(err, "[AdminChecker] load failed");
            }
        })();
    }

    render() {
        const { children } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return null;
        }

        return (<Provider value={this.availableRoutes}>
            <Consumer>
                {children}
            </Consumer>
        </Provider>);
    }
}

export default withFirebaseUser(AdminChecker);
