import React, { useEffect } from "react";
import styled from "styled-components";
import "../../styles/blueprint_styles";

import FetchingClickShield from "js/react/components/FetchingClickShield";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import EnterpriseController from "js/react/views/Admin/controllers/EnterpriseController";

import CreateInviteForm from "./CreateInviteForm";
import ConvertOrgToEnterpriseForm from "./ConvertOrgToEnterpriseForm";
import ManageNotificationsForm from "./ManageNotificationsForm";
import InvitesTable from "./InvitesTable";
import Filters from "./Filters";
import ExportToCSV from "./ExportToCSV";

const PageContainer = styled.div`
    position: relative;
    background: #e6e6e6;
    display: flex;
    flex-flow: column;
    min-height: calc(100vh - 58px);
    width: 100%;

    // Global blueprint styles
    .bp4-label,.bp4-switch {
        color: #989898;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: flex-start;

    width: 100%;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 20px;
    color: #2d2d2d;
    font-size: 1.5em;

    padding: 20px;
`;

const ControlsContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const ControlContainer = styled.div`
    padding: 10px;
    margin: 10px 0 10px 10px;
    border: 1px solid #d6d6d6;
`;

function Enterprise({ isInitialized, firebaseUser, handleShowDialog }) {
    useEffect(() => {
        EnterpriseController.initialize(firebaseUser, handleShowDialog);
    }, [0]);

    if (!isInitialized) {
        return (<PageContainer><FetchingClickShield visible backgroundColor="#e6e6e6" /></PageContainer>);
    }

    return (<PageContainer>
        <HeaderContainer>Enterprise trial invites</HeaderContainer>
        <ControlsContainer>
            <ControlContainer><CreateInviteForm /></ControlContainer>
            <div>
                <ControlContainer><ManageNotificationsForm /></ControlContainer>
                <ControlContainer><Filters /></ControlContainer>
                <ControlContainer><ExportToCSV /></ControlContainer>
            </div>
            <ControlContainer><ConvertOrgToEnterpriseForm /></ControlContainer>
        </ControlsContainer>
        <InvitesTable />
    </PageContainer>);
}

export default withFirebaseUser(
    EnterpriseController.withState(
        Enterprise
    )
);
