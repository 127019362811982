import moment from "moment";
import React from "react";
import styled from "styled-components";

import { Button, Tag, Switch } from "@blueprintjs/core";

import ReactTable from "react-table";

import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import ProvisioningController, { ProvisioningControllerState } from "js/react/views/Admin/controllers/ProvisioningController";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

const TableContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 300px;

    .rt-td {
        color: #333;
        font-size: 13px;
    }

    .bp4-switch {
        height: 6px;
        margin-left: 5px;
    }

    .bp4-control.bp4-switch.switch-red input:checked ~ .bp4-control-indicator {
        background: #ff5c5c;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 5px;
    padding: 2px 0;

    >.bp4-button {
        margin: 0 3px;
    }

    >.bp4-button:focus {
        outline: none;
    }
`;

const RedirectURIsContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 5px;
`;

function SourcesTable({ sources, prices, fetching }: ProvisioningControllerState) {
    return (<TableContainer>
        <ReactTable
            data={sources}
            defaultPageSize={10}
            filterable
            columns={[
                {
                    id: "createdAt",
                    Header: "Created",
                    accessor: ({ createdAt }) => moment(createdAt).format("MM/DD/YYYY"),
                    filterMethod: ({ value }, { createdAt }) => createdAt.includes(value),
                    Cell: ({ original: { createdAt } }) => (<span>
                        {moment(createdAt).format("MM/DD/YYYY")}
                    </span>)
                },
                {
                    Header: "Name",
                    accessor: "name"
                },
                {
                    Header: "Price",
                    accessor: "priceId",
                    Cell: ({ original: { userSubscriptionConfig: { priceId } } }) => <span>{prices.find(price => price.priceId === priceId)?.label || "N/A"}</span>,
                    filterable: false
                },
                {
                    Header: "Trial duration",
                    Cell: ({ original: { userSubscriptionConfig: { trialDurationDays } } }) => (<Tag intent="primary">{trialDurationDays} days</Tag>),
                    filterable: false
                },
                {
                    Header: "Redirect URIs",
                    Cell: ({ original: { authConfig: { redirectURIs } } }) => (
                        <RedirectURIsContainer>
                            {redirectURIs.map((uri, index) => (
                                <div key={index}>{uri}</div>
                            ))}
                        </RedirectURIsContainer>
                    ),
                    filterable: false
                },
                {
                    Header: "Client ID",
                    accessor: "authConfig.clientId"
                },
                {
                    id: "enabled",
                    Header: "Enabled",
                    accessor: ({ enabled }) => enabled ? "enabled" : "disabled",
                    Cell: ({ original: { id, enabled } }) => (
                        <Switch
                            checked={!!enabled}
                            disabled={fetching}
                            innerLabelChecked="Enabled"
                            innerLabel="Disabled"
                            // @ts-ignore
                            onChange={event => ProvisioningController.updateSource(id, { enabled: event.target.checked })}
                        />
                    ),
                    filterable: false
                },
                {
                    id: "credentials",
                    Cell: ({ original: { authConfig: { clientSecret } } }) => (
                        <ButtonsContainer>
                            <Button
                                outlined
                                disabled={fetching}
                                text="Copy Client Secret"
                                rightIcon="third-party"
                                intent="success"
                                onClick={() => clipboardWrite({ [ClipboardType.TEXT]: clientSecret })}
                                small
                            />
                        </ButtonsContainer>
                    ),
                    Header: "",
                    resizable: false,
                    width: 200,
                    filterable: false
                },
                {
                    Header: "",
                    resizable: false,
                    filterable: false,
                    width: 180,
                    Cell: ({ original: { authConfig: { clientId } } }) => (
                        <Button
                            outlined
                            intent="primary"
                            text="Copy Sign In URL"
                            rightIcon="clipboard"
                            onClick={() => {
                                const search = new URLSearchParams();
                                search.set("scope", "signin");
                                search.set("access_type", "offline");
                                search.set("response_type", "code");
                                search.set("state", "test_state");
                                search.set("redirect_uri", `${window.location.origin}/provisioning/oauth2/callback`);
                                search.set("client_id", clientId);
                                search.set("sid", "test_sid");
                                search.set("prompt", "login");
                                clipboardWrite({ [ClipboardType.TEXT]: `${window.location.origin}/provisioning/oauth2/auth?${search.toString()}` });
                            }} small />
                    )
                },
                {
                    Header: "",
                    resizable: false,
                    filterable: false,
                    width: 60,
                    Cell: ({ original: { id } }) => (
                        <Button
                            outlined
                            text="Edit"
                            onClick={() => ProvisioningController.startEditingSource(id)}
                            small
                        />
                    )
                }
            ]}
        />
    </TableContainer>);
}

export default withFirebaseUser(ProvisioningController.withState(SourcesTable));
