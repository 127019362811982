import theme from "js/react/materialThemeOverrides";
const { primary, grey } = theme.palette;

export const blockStyles = {
    block: {
        backgroundColor: grey[50],
        color: grey[400],
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        transition: "all 200ms ease-in",
        "& h1": {
            margin: "0 0 20px 0"
        },
        position: "relative"
    },

    contentContainer: {
        width: "100%",
    },

    descriptionContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
    },

    expandedBlock: {},

    inputSection: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "20px",
        fontFamily: "sans-serif"
    },

    multiInputSection: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "flex-start",
        marginTop: "20px",
        fontFamily: "sans-serif"
    },

    inputContainer: {
        width: "100%",
        marginBottom: "20px"
    },

    resultsSection: {
        width: "100%",
        // border: "2px solid #d8d8d8",
        marginTop: "20px",
    },

    datetime: {
        color: grey[400],
        backgroundColor: "transparent",
        flexGrow: "1",
        fontFamily: "sans-serif",
        margin: "10px 10px 10px 0",
        width: "100%"
    }
};
