import React, { useState } from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { blockStyles } from "./styles/block_styles";
import { TextInput } from "./Inputs";

const ButtonBlock = ({
    classes,
    buttonLabel,
    handleClick,
    description,
    title,
    text = "",
    showInput = false
}) => {
    const [value, setValue] = useState(text);

    const onChange = value => {
        setValue(value);
    };

    return (<div className={classes.block}>
        <div className={classes.contentContainer}>
            <h1>{title}</h1>
            <div className={classes.descriptionContainer}>
                <div>{description}</div>
                {showInput && <TextInput value={value} onChange={onChange} label="Enter Message" />}
            </div>
        </div>
        <div className={classes.inputSection}>
            <Button
                onClick={() => handleClick(value ?? "")}
                variant="outlined"
                color="default"
            >
                {buttonLabel}
            </Button>
        </div>
    </div>);
};

ButtonBlock.propTypes = {
    classes: PropTypes.object,
    buttonLabel: PropTypes.string,
    description: PropTypes.string,
    handleClick: PropTypes.func,
    title: PropTypes.string
};

export default withStyles(blockStyles)(ButtonBlock);
