import React from "react";
import styled from "styled-components";
import moment from "moment";

import { Button, Icon, Tag, Switch } from "@blueprintjs/core";

import ReactTable from "react-table";

import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import EnterpriseController from "js/react/views/Admin/controllers/EnterpriseController";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { SubscriptionStatus } from "common/constants";

const TableContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 300px;

    .rt-td {
        color: #333;
        font-size: 13px;
    }

    .bp4-switch {
        height: 6px;
        margin-left: 5px;
    }

    .bp4-control.bp4-switch.switch-red input:checked ~ .bp4-control-indicator {
        background: #ff5c5c;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 5px;
    padding: 2px 0;

    >.bp4-button {
        margin: 0 3px;
    }

    >.bp4-button:focus {
        outline: none;
    }
`;

function InvitesTable({ invites, fetching, firebaseUser, filters: { showOnlyMyInvites } }) {
    return (<TableContainer>
        <ReactTable
            data={invites}
            defaultPageSize={10}
            filterable
            columns={[
                showOnlyMyInvites ? null : {
                    Header: "AE",
                    accessor: "invitedByEmail"
                },
                {
                    Header: "Invitee email",
                    accessor: "inviteeEmail"
                },
                {
                    Header: "Team name",
                    accessor: "teamName"
                },
                {
                    Header: "Price and seats",
                    accessor: "",
                    Cell: ({ original: { pricePerSeat, seatCount } }) => <span>${pricePerSeat} x {seatCount} <i>(total ${pricePerSeat * seatCount})</i></span>,
                    filterable: false
                },
                {
                    Header: "Billing interval",
                    accessor: "billingInterval",
                    Cell: ({ original: { billingInterval } }) => (<Tag intent={billingInterval === "year" ? "success" : "primary"}>{billingInterval}</Tag>),
                    filterable: false
                },
                {
                    Header: "Trial duration (days)",
                    accessor: "trialDurationDays",
                    filterable: false
                },
                {
                    id: "invitedOn",
                    Header: "Invited on",
                    accessor: ({ createdAt }) => moment(createdAt).format("MM/DD/YYYY"),
                    filterMethod: ({ value }, { invitedOn }) => invitedOn.includes(value),
                    Cell: ({ original: { createdAt } }) => (<span>
                        {moment(createdAt).format("MM/DD/YYYY")}
                    </span>)
                },
                {
                    id: "acceptedOn",
                    Header: "Accepted on",
                    accessor: ({ acceptedAt }) => acceptedAt ? moment(acceptedAt).format("MM/DD/YYYY") : "",
                    filterMethod: ({ value }, { acceptedOn }) => acceptedOn.includes(value),
                    Cell: ({ original: { acceptedAt } }) => {
                        if (acceptedAt) {
                            return (<span>
                                <Icon icon="tick" intent="success" />
                                &nbsp;{moment(acceptedAt).format("MM/DD/YYYY")}
                            </span>);
                        }

                        return <Icon icon="cross" intent="warning" />;
                    }
                },
                {
                    id: "trialEndsIn",
                    Header: "Trial ends",
                    accessor: ({ acceptedAt, trialDurationDays }) => acceptedAt ? moment.duration(moment(acceptedAt).add(trialDurationDays, "day").diff(moment())).humanize() : "",
                    filterMethod: ({ value }, { trialEndsIn }) => trialEndsIn.includes(value),
                    Cell: ({ original: { acceptedAt, trialDurationDays } }) => {
                        if (acceptedAt) {
                            const trialEndsAt = moment(acceptedAt).add(trialDurationDays, "day");
                            const hasEnded = trialEndsAt.isBefore(moment());
                            return (<span>
                                {hasEnded && "Ended "}
                                {!hasEnded && "In "}
                                {moment.duration(trialEndsAt.diff(moment())).humanize()}
                                {hasEnded && " ago"}
                            </span>);
                        }

                        return <span></span>;
                    },
                    filterable: false
                },
                {
                    Header: "Subscription status",
                    accessor: "subscriptionStatus",
                    Cell: ({ original: { subscriptionStatus } }) => {
                        if (!subscriptionStatus) {
                            return null;
                        }

                        let intent = "warning";
                        if (subscriptionStatus === SubscriptionStatus.TRIALING) {
                            intent = "primary";
                        } else if (subscriptionStatus === SubscriptionStatus.ACTIVE) {
                            intent = "success";
                        } else if (subscriptionStatus === SubscriptionStatus.PAST_DUE) {
                            intent = "danger";
                        }

                        return <Tag intent={intent}>{subscriptionStatus.charAt(0).toUpperCase() + subscriptionStatus.slice(1).replaceAll("_", " ")}</Tag>;
                    },
                },
                {
                    id: "forceLockWorkspace",
                    Header: "Lock workspace",
                    accessor: ({ forceLockWorkspace }) => forceLockWorkspace ? "locked" : "unlocked",
                    Cell: ({ original: { id, forceLockWorkspace, invitedBy, acceptedAt } }) => (
                        <Switch
                            checked={!!forceLockWorkspace}
                            disabled={fetching || !acceptedAt}
                            innerLabelChecked="Locked"
                            innerLabel="Unlocked"
                            className="switch-red"
                            onChange={event => EnterpriseController.updateInvite(id, { forceLockWorkspace: event.target.checked })}
                        />
                    ),
                    filterable: false
                },
                {
                    id: "notifyInviter",
                    Header: "Notifications",
                    accessor: ({ notifyInviter }) => notifyInviter ? "yes" : "no",
                    Cell: ({ original: { id, notifyInviter, invitedBy } }) => (
                        <Switch
                            checked={!!notifyInviter}
                            disabled={fetching || invitedBy !== firebaseUser.uid}
                            innerLabelChecked="Yes"
                            innerLabel="No"
                            onChange={event => EnterpriseController.updateInvite(id, { notifyInviter: event.target.checked })}
                        />
                    ),
                    filterable: false
                },
                {
                    Cell: ({ original: { id, acceptedAt, createdOrgId, link, openInvoice, inviteeEmail } }) => {
                        if (acceptedAt) {
                            return (<ButtonsContainer>
                                <Button
                                    outlined
                                    disabled={fetching}
                                    text="Copy Org Id"
                                    rightIcon="third-party"
                                    intent="success"
                                    onClick={() => clipboardWrite({ [ClipboardType.TEXT]: createdOrgId })}
                                    small
                                />
                                {openInvoice && <>
                                    <Button
                                        outlined
                                        disabled={fetching}
                                        rightIcon="share"
                                        text="Open Invoice"
                                        intent="primary"
                                        onClick={() => window.open(openInvoice.paymentLink, "_blank")}
                                        small
                                    />
                                    <Button
                                        outlined
                                        disabled={fetching}
                                        rightIcon="envelope"
                                        text="Send Invoice by Email"
                                        intent="warning"
                                        onClick={() => EnterpriseController.sendInvoiceByEmail(id)}
                                        small
                                    />
                                </>
                                }
                            </ButtonsContainer>);
                        }

                        return (<ButtonsContainer>
                            <Button
                                outlined
                                disabled={fetching}
                                rightIcon="link"
                                text="Copy Link"
                                intent="success"
                                onClick={() => clipboardWrite({ [ClipboardType.TEXT]: link })}
                                small
                                data-testid="link"
                                data-testemail={inviteeEmail}
                                data-testlink={link}
                            />
                            <Button
                                outlined
                                disabled={fetching}
                                rightIcon="trash"
                                text="Delete"
                                intent="warning"
                                onClick={() => EnterpriseController.deleteInvite(id)}
                                small
                            />
                        </ButtonsContainer>);
                    },
                    Header: "",
                    accessor: "",
                    resizable: false,
                    width: 220,
                    filterable: false
                }
            ].filter(column => !!column)}
        />
    </TableContainer>);
}

export default withFirebaseUser(EnterpriseController.withState(InvitesTable));
