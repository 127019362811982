import theme from "js/react/materialThemeOverrides";
const { primary, grey } = theme.palette;

export const pageStyles = {
    page: {
        padding: "20px 50px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        marginBottom: "-1px"
    },
    pageTitle: {
        width: "100%",
        borderBottom: `1px solid ${grey[100]}`
    },
    pageNotice: {
        textTransform: "uppercase",
        fontWeight: 500
    },
    blockGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(440px, 1fr))",
        gridGap: "40px"
    },
    ScrollableblockGrid: {
        height: "calc(100vh - 175px)",
        overflow: "auto",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(440px, 1fr))",
        gridGap: "40px"
    },
};
