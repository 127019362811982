import { GlobalStateController } from "bai-react-global-state";

import { IProvisioningSource } from "common/interfaces/models";
import stripeProducts from "common/stripeProducts";
import getLogger, { LogGroup } from "js/core/logger";
import { fetchWithAuth } from "js/react/views/Admin/utils/fetchWithAuth";

const logger = getLogger(LogGroup.AI);

export interface ProvisioningControllerState {
    isInitialized: boolean;
    fetching: boolean;
    sources: IProvisioningSource[];
    editingSource: IProvisioningSource;
    prices: {
        productName: string;
        productId: string;
        priceId: string;
        billingInterval: string;
        amount: number;
        label: string;
        coupons: {
            id: string;
            percent_off: number;
            duration: string;
            currency: string;
            name: string;
        }[];
    }[]
}

const initialState: ProvisioningControllerState = {
    isInitialized: false,
    fetching: true,
    sources: [],
    prices: [],
    editingSource: null
};

class ProvisioningController extends GlobalStateController<ProvisioningControllerState> {
    private _firebaseUser: null | Record<string, any>;
    private _handleShowDialog: null | ((title: string, body: string, props?: Record<string, any>) => void);

    public async initialize(firebaseUser, handleShowDialog) {
        this._firebaseUser = firebaseUser;
        this._handleShowDialog = handleShowDialog;

        await this._setPrices();
        await this.loadSources();

        await this._updateState({ isInitialized: true });
    }

    private async _setPrices() {
        const prices: ProvisioningControllerState["prices"] = [];
        Object.values(stripeProducts).forEach(product => {
            product.plans.forEach(plan => {
                if (!plan.isDefault) {
                    return;
                }

                prices.push({
                    productName: product.productName,
                    productId: product.productId,
                    priceId: plan.planId,
                    billingInterval: plan.interval,
                    amount: plan.amount,
                    label: `${product.productName} - ${plan.interval} - $${plan.amount / 100}`,
                    coupons: product.coupons
                });
            });
        });

        await this._updateState({ prices });
    }

    public async loadSources() {
        this._updateState({ fetching: true });
        try {
            const { sources } = await fetchWithAuth("/admin/provisioning-sources", { method: "GET" }, this._firebaseUser);
            await this._updateState({ sources });
        } catch (err) {
            logger.error(err, "[ProvisioningController] loadSources() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            this._updateState({ fetching: false });
        }
    }

    public async createSource(source: Omit<IProvisioningSource, "id" | "createdAt" | "modifiedAt">) {
        this._updateState({ fetching: true });
        try {
            const { source: createdSource } = await fetchWithAuth("/admin/provisioning-sources", { method: "POST", body: JSON.stringify({ source }) }, this._firebaseUser);
            await this._updateState({ sources: [...this._state.sources, createdSource] });
        } catch (err) {
            logger.error(err, "[ProvisioningController] createSource() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            this._updateState({ fetching: false });
        }
    }

    public async updateSource(sourceId: string, source: Partial<IProvisioningSource>) {
        this._updateState({ fetching: true });
        try {
            const { source: updatedSource } = await fetchWithAuth(`/admin/provisioning-sources/${sourceId}`, { method: "PUT", body: JSON.stringify({ source }) }, this._firebaseUser);
            await this._updateState({ sources: this._state.sources.map(source => source.id === sourceId ? updatedSource : source) });
        } catch (err) {
            logger.error(err, "[ProvisioningController] updateSource() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            this._updateState({ fetching: false });
        }
    }

    public async startEditingSource(sourceId: string) {
        const source = this._state.sources.find(source => source.id === sourceId);
        await this._updateState({ editingSource: source });
    }

    public async finishEditingSource() {
        await this._updateState({ editingSource: null });
    }
}

export default new ProvisioningController(initialState);
