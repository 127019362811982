import React, { useEffect } from "react";
import styled from "styled-components";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import FetchingClickShield from "js/react/components/FetchingClickShield";
import { Button } from "@blueprintjs/core";

import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import ManageAIController from "js/react/views/Admin/controllers/ManageAIController";
import { AiTokenUsageReportByModelsType } from "common/aiConstants";
import ReportByModels from "js/react/views/Admin/pages/ManageAI/ReportByModels";
import ReportByPresentations from "js/react/views/Admin/pages/ManageAI/ReportByPresentations";

const PageContainer = styled.div`
    max-height: calc(100vh - 58px);
    overflow: auto;
    position: relative;
    background: #e6e6e6;
    display: flex;
    flex-flow: column;
    min-height: calc(100vh - 58px);
    width: 100%;

    // Global blueprint styles
    .bp4-label,.bp4-switch {
        color: #989898;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 20px;
    color: #2d2d2d;
    font-size: 1.5em;

    padding: 20px 10px 20px 20px;
`;

function ManageAI({ isInitialized, fetching, firebaseUser, handleShowDialog }) {
    useEffect(() => {
        ManageAIController.initialize(firebaseUser, handleShowDialog);
    }, [0]);

    if (!isInitialized) {
        return (<PageContainer><FetchingClickShield visible backgroundColor="#e6e6e6" /></PageContainer>);
    }

    return (<PageContainer>
        <HeaderContainer>
            AI Management and statistics
            <Button rightIcon="refresh" intent="success" text="Refresh" disabled={fetching} loading={fetching} onClick={() => ManageAIController.fetchReports()} />
        </HeaderContainer>
        <ReportByPresentations />
        {Object.values(AiTokenUsageReportByModelsType).map(reportType =>
            <ReportByModels key={reportType} reportType={reportType} />
        )}
    </PageContainer>);
}

export default withFirebaseUser(
    ManageAIController.withState(
        ManageAI
    )
);
