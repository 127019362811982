import React, { Component } from "reactn";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Input, Button } from "@material-ui/core";
import { blockStyles } from "./styles/block_styles";
import theme from "js/react/materialThemeOverrides";
const { grey } = theme.palette;

class InputButtonBlock extends Component {
    state = {
        inputValue: ""
    };

    handleInputChanged = e => {
        this.setState({
            inputValue: e.target.value
        });
    };

    handleClick = () => {
        const { handleClick, clearOnClick } = this.props;
        handleClick(this.state.inputValue);
        if (clearOnClick) {
            this.setState({
                inputValue: ""
            });
        }
    };

    render() {
        const {
            children,
            classes,
            buttonLabel,
            description,
            title,
            placeholder
        } = this.props;

        const { inputValue } = this.state;

        return (
            <div
                className={classNames({
                    [classes.block]: true,
                    [classes.expandedBlock]: !!children
                })}
            >
                <div>
                    <h1>{title}</h1>
                    <div>{description}</div>
                </div>
                <div className={classes.inputSection}>
                    <Input
                        onChange={this.handleInputChanged}
                        onKeyUp={e => {
                            if (e.key === "Enter" && inputValue.trim() !== "") {
                                this.handleClick();
                            }
                        }}
                        placeholder={placeholder}
                        style={{
                            borderColor: grey[900],
                            color: grey[900],
                            flexGrow: "1",
                            fontFamily: "sans-serif",
                            marginRight: "10px"
                        }}
                        value={inputValue}
                    />
                    <Button
                        disabled={inputValue.trim() === ""}
                        onClick={this.handleClick}
                        variant="outlined"
                        color="default"
                    >
                        {buttonLabel}
                    </Button>
                </div>
                {children}
            </div>
        );
    }
}

InputButtonBlock.propTypes = {
    clearOnClick: PropTypes.bool,
    classes: PropTypes.object,
    buttonLabel: PropTypes.string,
    description: PropTypes.string,
    handleClick: PropTypes.func,
    title: PropTypes.string,
    placeholder: PropTypes.string
};

export default withTheme(withStyles(blockStyles)(InputButtonBlock));
