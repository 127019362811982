import React from "react";
import styled from "styled-components";
import moment from "moment";
import { FormGroup, Button } from "@blueprintjs/core";

import { downloadFromBlob, downloadFromUrl } from "js/core/utilities/utilities";
import { _ } from "js/vendor";
import EnterpriseController from "js/react/views/Admin/controllers/EnterpriseController";

const ExportToCSVContainer = styled.div`
    >p {
        font-size: 18px;
        color: #2d2d2d;
        margin-bottom: 5px;
    }

    >.bp4-form-group {
        margin-bottom: 4px;

        >.bp4-form-content {
            >.bp4-button {
                width: 100%;
            }
        }
    } 
`;

const exportToCSV = ({ fetching, invites, filters: { showOnlyMyInvites } }) => {
    const downloadInvites = () => {
        const fieldList = [showOnlyMyInvites ? null : {
            header: "AE",
            field: "invitedByEmail"
        }, {
            header: "Invitee email",
            field: "inviteeEmail"
        }, {
            header: "Team name",
            field: "teamName"
        }, {
            header: "Price and seats",
            field: "priceAndSeats",
        }, {
            header: "Billing interval",
            field: "billingInterval",
        }, {
            header: "Trial duration (days)",
            field: "trialDurationDays",
        }, {
            header: "Invited on",
            field: "invitedOn"
        }, {
            header: "Accepted on",
            field: "acceptedOn"
        }, {
            header: "Trial ends",
            field: "trialEndsIn"
        }, {
            header: "Subscription status",
            field: "subscriptionStatus",
        }, {
            header: "Lock workspace",
            field: "forceLockWorkspace"
        }, {
            header: "Notifications",
            field: "notifyInviter"
        }].filter(column => !!column);

        const pickedData = invites
            .map(invite => ({
                ...invite,
                invitedByEmail: invite.invitedByEmail || "",
                priceAndSeats: `$${invite.pricePerSeat} x ${invite.seatCount} (total $${invite.pricePerSeat * invite.seatCount})`,
                invitedOn: moment(invite.createdAt).format("MM/DD/YYYY"),
                acceptedOn: invite.acceptedAt ? moment(invite.acceptedAt).format("MM/DD/YYYY") : "",
                trialEndsIn: invite.acceptedAt ? moment.duration(moment(invite.acceptedAt).add(invite.trialDurationDays, "day").diff(moment())).humanize() : "",
                subscriptionStatus: invite.subscriptionStatus ? invite.subscriptionStatus.charAt(0).toUpperCase() + invite.subscriptionStatus.slice(1).replaceAll("_", " ") : "",
                forceLockWorkspace: invite.forceLockWorkspace ? "Locked" : "Unlocked",
                notifyInviter: invite.notifyInviter ? "Yes" : "No",
            })).map(invite => fieldList.map(({ field }) => invite[field]));

        const convertToCSV = arr => {
            const array = [fieldList.map(field => field.header)].concat(arr);

            return array.map(it => {
                return Object.values(it).toString();
            }).join("\n");
        };

        downloadFromBlob(new Blob([convertToCSV(pickedData)], { type: "text/csv;charset=utf-8;" }), "invites.csv", "text/csv;charset=utf-8;");
    };

    return (<ExportToCSVContainer>
        <p>Export to CSV</p>
        <FormGroup disabled={fetching} >
            <Button rightIcon="import" intent="success" text="Download" disabled={fetching} loading={fetching} onClick={() => downloadInvites()} />
        </FormGroup>
    </ExportToCSVContainer>);
};

export default EnterpriseController.withState(exportToCSV);
