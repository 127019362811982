import React, { Component } from "reactn";
import ButtonBlock from "../components/ButtonBlock";
import PropTypes from "prop-types";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { pageStyles } from "../styles/page_styles";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

class Maintenance extends Component {
    handleForceUpdateClicked = async () => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/force-update", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({})
        });

        if (!response.ok) {
            handleShowDialog(
                "Error!",
                `Could not initiate a force update. Please try again.`
            );
            return null;
        }

        handleShowDialog("Success!", `Force update initiated.`);
    };

    handleOptionalUpdateClicked = async () => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/optional-update", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({})
        });

        if (!response.ok) {
            handleShowDialog(
                "Error!",
                `Could not initiate an optional update. Please try again.`
            );
            return null;
        }

        handleShowDialog("Success!", `Optional update initiated.`);
    };

    handleMandatoryUpdateClicked = async () => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/mandatory-update", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({})
        });

        if (!response.ok) {
            handleShowDialog(
                "Error!",
                `Could not initiate an mandatory update. Please try again.`
            );
            return null;
        }

        handleShowDialog("Success!", `Mandatory update initiated.`);
    };

    handleActivateMaintenanceModeClicked = async message => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/maintenance", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                message
            })
        });

        if (!response.ok) {
            handleShowDialog(
                "Error!",
                `Could not initiate maintenance mode. Please try again.`
            );
            return null;
        }

        handleShowDialog(
            "Success!",
            `The server is now in maintenance mode. Please click Force Update or Optional Update to reactivate it.`
        );
    };

    render() {
        const { appVersion, classes } = this.props;
        return (
            <div className={classes.page}>
                <h2 className={classes.pageTitle}>
                    Maintenance Page | App Version: {appVersion}
                </h2>
                <div className={classes.pageNotice}>
                    NOTE: IF SERVER IS IN MAINTENANCE MODE, CLICK FORCE UPDATE
                    OR OPTIONAL UPDATE BUTTON TO REACTIVATE.
                </div>
                <div className={classes.blockGrid}>
                    <ButtonBlock
                        buttonLabel="Update"
                        description="Force update (removes maintenance mode)."
                        handleClick={this.handleForceUpdateClicked}
                        title="Force Update"
                    />
                    <ButtonBlock
                        buttonLabel="Update"
                        description="Optional update (removes maintenance mode)."
                        handleClick={this.handleOptionalUpdateClicked}
                        title="Optional Update"
                    />
                    <ButtonBlock
                        buttonLabel="Activate"
                        description="Activate maintenance mode."
                        handleClick={this.handleActivateMaintenanceModeClicked}
                        text="Sorry, we are upgrading our app. Please come back in a few minutes."
                        title="Maintenance"
                        showInput
                    />
                    <ButtonBlock
                        text="Sorry, we are upgrading our app. Please come back in a few minutes."
                        buttonLabel="Update"
                        description="Mandatory update (shows blocking dialog that user can click to refresh on and removes maintenance mode)."
                        handleClick={this.handleMandatoryUpdateClicked}
                        title="Mandatory Update"
                        showInput
                    />
                </div>
            </div>
        );
    }
}

Maintenance.propTypes = {
    appVersion: PropTypes.string,
    classes: PropTypes.object.isRequired,
    handleShowDialog: PropTypes.func.isRequired
};

export default withFirebaseUser(withTheme(withStyles(pageStyles)(Maintenance)));
