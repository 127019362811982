import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import _ from "lodash";

import { Button, FormGroup, HTMLSelect } from "@blueprintjs/core";

import {
    IProvisioningSource,
    ProvisioningSourceAuthType,
    ProvisioningSourceGrantType,
    ProvisioningSourceAnalyticsEventType
} from "common/interfaces";
import ProvisioningController, { ProvisioningControllerState } from "js/react/views/Admin/controllers/ProvisioningController";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import getObjectHash from "common/utils/getObjectHash";

import { NumericInput, TextInput, MultilineTextInput, SwitchInput } from "../../components/Inputs";

const Container = styled.div`
    >p {
        font-size: 18px;
        color: #2d2d2d;
        margin-bottom: 5px;
    }    
`;

const ContentContainer = styled.div`
    display: flex;
    flex-flow: row;
    gap: 10px;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 10px;

    >.bp4-form-group {
        margin: 0;
        width: 400px;
    } 

    >.bp4-button {
        margin-top: 23px;
        width: 100%;
    }
`;

function SourceForm({ fetching, prices, editingSource }: ProvisioningControllerState) {
    const getDefaultFormValues: () => Omit<IProvisioningSource, "id" | "createdAt" | "modifiedAt"> = () => {
        if (editingSource) {
            const source = _.cloneDeep(editingSource);
            source.analyticsConfig.enabled = !!source.analyticsConfig.enabled;
            source.authConfig.redirectURIs = [source.authConfig.redirectURIs.join(", ")];
            return source;
        }

        const price = prices.find(price => price.productName === "Pro" && price.billingInterval === "year");
        const couponId = price.coupons.find(coupon => coupon.percent_off === 15 && coupon.duration === "once")?.id;

        return {
            name: "",
            authConfig: {
                authType: ProvisioningSourceAuthType.OAUTH2,
                clientId: uuid().replace(/-/g, ""),
                clientSecret: uuid().replace(/-/g, ""),
                grants: [ProvisioningSourceGrantType.AUTHORIZATION_CODE, ProvisioningSourceGrantType.REFRESH_TOKEN],
                redirectURIs: [window.location.origin + "/provisioning/oauth2/callback"]
            },
            userSubscriptionConfig: {
                trialDurationDays: 30,
                priceId: price.priceId,
                couponId
            },
            analyticsConfig: {
                enabled: false,
                tokenEndpoint: {
                    url: "",
                    clientId: "",
                    clientSecret: ""
                },
                analyticsEndpoint: {
                    url: "",
                    method: "POST",
                    bodyTemplate: JSON.stringify({
                        "state": "{{event_type}}",
                        "source": {
                            "type": "isv",
                            "value": {
                                "isv_id": "ws-hp.com/beautiful.ai",
                                "sid": "{{sid}}"
                            }
                        }
                    }, null, 2)
                },
                supportedEventTypes: Object.values(ProvisioningSourceAnalyticsEventType)
            },
            enabled: true
        };
    };

    const [formValues, setFormValues] = useState(getDefaultFormValues());
    const [isFormValid, setFormValid] = useState(false);

    useEffect(() => {
        setFormValues(getDefaultFormValues());
    }, [editingSource]);

    useEffect(() => {
        setFormValid(
            !!formValues.name &&
            !!formValues.authConfig.clientId &&
            !!formValues.authConfig.clientSecret &&
            formValues.authConfig.grants.length > 0 &&
            formValues.authConfig.redirectURIs.length > 0 &&
            !!formValues.userSubscriptionConfig.priceId &&
            (
                !formValues.analyticsConfig.enabled ||
                (
                    !!formValues.analyticsConfig.tokenEndpoint.url &&
                    !!formValues.analyticsConfig.tokenEndpoint.clientId &&
                    !!formValues.analyticsConfig.tokenEndpoint.clientSecret &&
                    !!formValues.analyticsConfig.analyticsEndpoint.url &&
                    !!formValues.analyticsConfig.analyticsEndpoint.method &&
                    !!formValues.analyticsConfig.analyticsEndpoint.bodyTemplate &&
                    formValues.analyticsConfig.supportedEventTypes.length > 0)
            )
        );
    }, [getObjectHash(formValues)]);

    const handleCreateSource = async () => {
        formValues.authConfig.redirectURIs = formValues.authConfig.redirectURIs[0].split(",").filter(Boolean).map(uri => uri.trim());
        await ProvisioningController.createSource(formValues);
        setFormValues(getDefaultFormValues());
    };

    const handleUpdateSource = async () => {
        formValues.authConfig.redirectURIs = formValues.authConfig.redirectURIs[0].split(",").filter(Boolean).map(uri => uri.trim());
        await ProvisioningController.updateSource(editingSource.id, { ...editingSource, ...formValues });
        await ProvisioningController.finishEditingSource();
    };

    function getFormValue<T = string>(formKey: string): T {
        return _.get(formValues, formKey) as T;
    }
    function setFormValue<T = string>(formKey: string, value: T) {
        setFormValues({ ..._.set(formValues, formKey, value) });
    }

    const price = prices.find(price => price.priceId === getFormValue("userSubscriptionConfig.priceId"));
    const coupon = price.coupons.find(coupon => coupon.id === getFormValue("userSubscriptionConfig.couponId"));

    return (<Container>
        {editingSource && <p>Editing source {editingSource.id}</p>}
        {!editingSource && <p>Add new source</p>}
        <ContentContainer>
            <InputsContainer>
                <TextInput
                    label="Name"
                    value={getFormValue("name")}
                    onChange={value => setFormValue("name", value)}
                    disabled={fetching}
                />
                <FormGroup label="Price" disabled={fetching}>
                    <HTMLSelect
                        fill
                        disabled={fetching}
                        options={prices.map(({ label }) => label)}
                        value={prices.find(p => p.priceId === getFormValue("userSubscriptionConfig.priceId"))?.label}
                        onChange={event => setFormValue("userSubscriptionConfig.priceId", prices.find(p => p.label === event.target.value)?.priceId)}
                    />
                </FormGroup>
                <NumericInput
                    label="Tiral duration (days)"
                    value={getFormValue<number>("userSubscriptionConfig.trialDurationDays")}
                    onChange={value => setFormValue<number>("userSubscriptionConfig.trialDurationDays", parseInt(value))}
                    disabled={fetching}
                />
                <FormGroup label="Coupon" disabled={fetching}>
                    <HTMLSelect
                        fill
                        disabled={fetching}
                        options={[...price.coupons.map(({ name }) => name), "No discount"]}
                        value={price.coupons.find(c => c.id === getFormValue("userSubscriptionConfig.couponId"))?.name ?? "No discount"}
                        onChange={event => setFormValue("userSubscriptionConfig.couponId", price.coupons.find(c => c.name === event.target.value)?.id ?? null)}
                    />
                </FormGroup>
                <TextInput
                    label="Client ID"
                    value={getFormValue("authConfig.clientId")}
                    onChange={value => setFormValue("authConfig.clientId", value)}
                    disabled={fetching}
                />
                <TextInput
                    label="Client Secret"
                    value={getFormValue("authConfig.clientSecret")}
                    onChange={value => setFormValue("authConfig.clientSecret", value)}
                    disabled={fetching}
                />
                <TextInput
                    label="Redirect URIs (comma delimited)"
                    value={getFormValue("authConfig.redirectURIs[0]")}
                    onChange={value => setFormValue("authConfig.redirectURIs[0]", value)}
                    disabled={fetching}
                />
            </InputsContainer>
            <InputsContainer>
                <SwitchInput
                    label="Analytics Enabled"
                    value={getFormValue<boolean>("analyticsConfig.enabled")}
                    values={[true, false]}
                    disabled={fetching}
                    onChange={value => setFormValue<boolean>("analyticsConfig.enabled", value)}
                />
                <TextInput
                    label="Token Endpoint URL"
                    value={getFormValue("analyticsConfig.tokenEndpoint.url")}
                    onChange={value => setFormValue("analyticsConfig.tokenEndpoint.url", value)}
                    disabled={fetching}
                />
                <TextInput
                    label="Token Endpoint Client ID"
                    value={getFormValue("analyticsConfig.tokenEndpoint.clientId")}
                    onChange={value => setFormValue("analyticsConfig.tokenEndpoint.clientId", value)}
                    disabled={fetching}
                />
                <TextInput
                    label="Token Endpoint Client Secret"
                    value={getFormValue("analyticsConfig.tokenEndpoint.clientSecret")}
                    onChange={value => setFormValue("analyticsConfig.tokenEndpoint.clientSecret", value)}
                    disabled={fetching}
                />
                <TextInput
                    label="Analytics Endpoint URL"
                    value={getFormValue("analyticsConfig.analyticsEndpoint.url")}
                    onChange={value => setFormValue("analyticsConfig.analyticsEndpoint.url", value)}
                    disabled={fetching}
                />
                <FormGroup label="Analytics Endpoint Method" disabled={fetching}>
                    <HTMLSelect
                        fill
                        disabled={fetching}
                        options={["POST", "PUT", "PATCH"]}
                        value={getFormValue("analyticsConfig.analyticsEndpoint.method")}
                        onChange={event => setFormValue("analyticsConfig.analyticsEndpoint.method", event.target.value)}
                    />
                </FormGroup>
                <MultilineTextInput
                    label="Analytics Endpoint Body Template"
                    value={getFormValue("analyticsConfig.analyticsEndpoint.bodyTemplate")}
                    onChange={value => setFormValue("analyticsConfig.analyticsEndpoint.bodyTemplate", value)}
                    disabled={fetching}
                />
            </InputsContainer>
            <Button
                rightIcon="arrow-right"
                intent="success"
                text={editingSource ? "Update" : "Create"}
                disabled={!isFormValid || fetching}
                loading={fetching}
                onClick={editingSource ? handleUpdateSource : handleCreateSource}
            />
            {editingSource && <Button outlined text="Cancel" onClick={() => ProvisioningController.finishEditingSource()} small />}
        </ContentContainer>
    </Container>);
}

export default withFirebaseUser(ProvisioningController.withState(SourceForm));
