import React from "reactn";
import ReportBlock from "./ReportBlock";

const KeyValueReport = props => {
    return (
        <ReportBlock
            {...props}
            render={queryResults => {
                const { columns } = props;
                return (
                    <ul style={{ whiteSpace: "pre-wrap" }}>
                        {columns.map(config => {
                            let key = config.key;
                            let value = queryResults[key];
                            if (config.render) {
                                return config.render(key, value);
                            }
                            return (
                                <li key={key}>
                                    <b>{config.label}:</b> {value}
                                </li>
                            );
                        })}
                    </ul>
                );
            }}
        />
    );
};

export default KeyValueReport;
