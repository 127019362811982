export class FetchError extends Error {
    constructor(message, status, text) {
        super(message);
        this.status = status;
        this.text = text;
    }
}

export async function fetchWithAuth(url, fetchOptions = {}, firebaseUser) {
    const idToken = await firebaseUser.getIdToken();
    const resp = await fetch(url, {
        ...fetchOptions,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            ...(fetchOptions.headers ?? {}),
            "Authorization": `Bearer ${idToken}`
        }
    });

    if (!resp.ok) {
        const text = await resp.text().catch(() => { });
        throw new FetchError("Fetch error", resp.status, text);
    }

    try {
        const json = await resp.json();
        return json;
    } catch {
        return await resp.text();
    }
}
