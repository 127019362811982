import React from "reactn";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { withStyles, withTheme } from "@material-ui/core/styles";

import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { navBarStyles } from "./styles/navBar_styles";

const NavBar = ({ classes, firebaseUser, options }) => (
    <div className={classes.navBar}>
        {options.map(option => (
            <NavLink
                activeClassName={classes.activeNavBarLink}
                className={classes.navBarItem}
                exact={option.fragment === ""}
                key={`${option.name}_navlink`}
                to={`${option.fragment}/`}
            >
                {option.name}
            </NavLink>
        ))}
        <div className={classes.navBarUser}>
            {firebaseUser.displayName || firebaseUser.email}
        </div>
    </div>
);

NavBar.propTypes = {
    classes: PropTypes.object,
    options: PropTypes.array
};

export default withFirebaseUser(withTheme(withStyles(navBarStyles)(NavBar)));
