import theme from "js/react/materialThemeOverrides";
const { primary, grey } = theme.palette;

export const navBarStyles = {
    navBar: {
        width: "100%",
        backgroundColor: grey[600],
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start"
    },

    navBarItem: {
        padding: "20px",
        cursor: "default",
        display: "inline-block",
        userSelect: "none",
        transition: "all 200ms ease-in",
        textDecoration: "none",
        color: grey[300],
        "&:hover": {
            backgroundColor: grey[400],
            color: grey[200]
        }
    },

    navBarUser: {
        padding: "20px",
        cursor: "default",
        display: "inline-block",
        userSelect: "none",
        transition: "all 200ms ease-in",
        textDecoration: "none",
        color: grey[300],
        marginLeft: "auto"
    },

    activeNavBarLink: {
        color: grey[100],
        backgroundColor: grey[400]
    }
};
