import { withStyles, withTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import { ds } from "js/core/models/dataService";
import { SharedThemes } from "js/core/models/sharedTheme";
import { BuiltInThemes, UserThemes } from "js/core/models/theme";
import AlertDialog from "js/react/components/Dialogs/AlertDialog";
import { Authenticate } from "js/react/views/Auth/Authenticate";
import { FirebaseAuthProvider } from "js/react/views/Auth/FirebaseAuthContext";
import { FirebaseAuthInitalizer } from "js/react/views/Auth/FirebaseAuthInitalizer";

import AdminChecker from "./components/AdminChecker";
import NavBar from "./components/NavBar";
import { appStyles } from "./styles/app_styles";

import {
    Edu,
    Enterprise,
    General,
    Home,
    Maintenance,
    Manage,
    ManageAI,
    ManageEmails,
    MongoMigration,
    Provisioning,
    QuickPlayer,
    Reports
} from "./pages";

const navOptions = [
    { name: "Home", fragment: "" },
    { name: "General", fragment: "/general" },
    { name: "Reports", fragment: "/reports" },
    { name: "Quick Player", fragment: "/quick-player" },
    { name: "Manage", fragment: "/manage" },
    { name: "Edu", fragment: "/edu" },
    { name: "Maintenance", fragment: "/maintenance" },
    { name: "Enterprise", fragment: "/enterprise" },
    { name: "Mongo Migration", fragment: "/mongo-migration" },
    { name: "AI", fragment: "/ai" },
    { name: "Emails", fragment: "/emails" },
    { name: "Provisioning", fragment: "/provisioning" }
];

class AdminApp extends Component {
    state = {
        dialogMessage: null
    };

    componentDidMount() {
        ds.prepare({ SharedThemes, BuiltInThemes, UserThemes });
    }

    showDialog = (title, body, props) => {
        const dialogProps = {
            maxWidth: "md",
            fullWidth: true,
            ...props
        };
        this.setState({
            dialogMessage: {
                title,
                body
            },
            dialogProps
        });
    };

    closeDialog = () => {
        this.setState({
            dialogMessage: null,
            dialogProps: null
        });
    };

    render() {
        const { appVersion, classes } = this.props;
        const { dialogMessage, dialogProps } = this.state;

        const getNavOptions = availableRoutes => navOptions.filter(({ fragment }) => availableRoutes.includes(fragment));

        return (
            <FirebaseAuthProvider>
                <FirebaseAuthInitalizer>
                    <Authenticate defaultPage="signIn" reason="admin" noToggle>
                        <AdminChecker>
                            {availableRoutes => (
                                <Router basename={"/admin"}>
                                    {dialogMessage && (
                                        <AlertDialog
                                            title={dialogMessage.title}
                                            open={true}
                                            closeDialog={this.closeDialog}
                                            {...dialogProps}
                                        >
                                            {dialogMessage.body}
                                        </AlertDialog>
                                    )}
                                    <div className={classes.root}>
                                        <NavBar options={getNavOptions(availableRoutes)} />
                                        <>
                                            <Route
                                                path="/"
                                                exact
                                                render={props => (
                                                    <Home
                                                        handleShowDialog={this.showDialog}
                                                        navOptions={getNavOptions(availableRoutes)}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/general"
                                                render={props => (
                                                    <General
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/reports"
                                                render={props => (
                                                    <Reports
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/maintenance"
                                                render={props => (
                                                    <Maintenance
                                                        appVersion={appVersion}
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/quick-player"
                                                render={props => (
                                                    <QuickPlayer
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/manage"
                                                render={props => (
                                                    <Manage
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/edu"
                                                render={props => (
                                                    <Edu
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/enterprise"
                                                render={props => (
                                                    <Enterprise
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/mongo-migration"
                                                render={props => (
                                                    <MongoMigration
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/ai"
                                                render={props => (
                                                    <ManageAI
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path={"/emails"}
                                                render={props => (
                                                    <ManageEmails
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path={"/provisioning"}
                                                render={props => (
                                                    <Provisioning
                                                        handleShowDialog={this.showDialog}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                        </>
                                    </div>
                                </Router>
                            )}
                        </AdminChecker>
                    </Authenticate>
                </FirebaseAuthInitalizer>
            </FirebaseAuthProvider>
        );
    }
}

AdminApp.propTypes = {
    appVersion: PropTypes.string.isRequired,
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withTheme(withStyles(appStyles)(AdminApp));
