import React from "reactn";
import ReportBlock from "./ReportBlock";
import ReactTable from "react-table";

const DEFAULT_PAGE_SIZE = 10;

const TableReport = props => {
    return (
        <ReportBlock
            {...props}
            render={queryResults => {
                const { columns, tableProps } = props;
                const tableColumns =
                    columns ||
                    (queryResults &&
                        Object.keys(queryResults[0]).map(resultKey => ({
                            Header:
                                resultKey.charAt(0).toUpperCase() +
                                resultKey.slice(1),
                            accessor: resultKey
                        })));

                let defaultPageSize =
                    queryResults.length > DEFAULT_PAGE_SIZE
                        ? DEFAULT_PAGE_SIZE
                        : queryResults.length;
                return (
                    <ReactTable
                        columns={tableColumns}
                        data={queryResults}
                        defaultPageSize={defaultPageSize}
                        {...tableProps}
                    />
                );
            }}
        />
    );
};

export default TableReport;
