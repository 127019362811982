import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { pageStyles } from "../styles/page_styles";
import InputButtonBlock from "js/react/views/Admin/components/InputButtonBlock";
import { List, ListItem, ListItemText, Button } from "@material-ui/core";
import Spinner from "js/react/components/Spinner";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import Thumbnails from "js/core/models/thumbnails";
import { THUMBNAIL_SIZES } from "common/constants";

class QuickThumbnail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbnailUrl: null
        };
    }

    async componentWillMount() {
        const { selectedPresentation, slideId } = this.props;

        const size = "small";
        const url = await Thumbnails.getSignedUrlAndLoad(slideId, null, selectedPresentation, THUMBNAIL_SIZES[size].suffix);
        this.setState({
            thumbnailUrl: url
        });
    }

    render() {
        return (
            <div className="slide-thumbnail" style={{ float: "left", padding: ".5%", width: "32%" }}>
                {this.renderThumbnail()}
            </div>
        );
    }

    renderThumbnail() {
        if (this.state.thumbnailUrl) {
            return <img style={{ width: "100%" }} src={this.state.thumbnailUrl} />;
        } else {
            return <div style={{ width: "100%" }}><Spinner /></div>;
        }
    }
}

class QuickPlayer extends Component {
    state = {
        sips: null,
        showAll: false,
        presentation: null,
        selectedPresentation: null
    };

    async getPresentation(presId) {
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/getSips", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: presId })
        });
        const { sips, presentation, error } = await response.json();
        if (response.status !== 200) {
            this.setState({ errorMessage: error || "Could not load thumbnails", selectedPresentation: presId, sips: null, presentation: null, showAll: false });
            return;
        }
        this.setState({ selectedPresentation: presId, sips, presentation, showAll: false, errorMessage: null });
    }

    setFile(file) {
        const reader = new FileReader();
        reader.onload = () => {
            const presentations = reader.result.split("\n").filter(x => x);
            this.setState({ presentations });
        };
        reader.readAsBinaryString(file);
    }

    render() {
        const { classes } = this.props;
        const { sips, presentations, selectedPresentation, presentation, showAll, errorMessage } = this.state;

        let thumbnails = sips;
        if (thumbnails && !showAll) {
            thumbnails = thumbnails.slice(0, 9);
        }
        return (
            <div className={classes.page}>
                <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                    <InputButtonBlock
                        buttonLabel="View"
                        description="Player Thumbnails"
                        title="Quick Player"
                        placeholder="Presentation or Link id"
                        handleClick={this.getPresentation.bind(this)}
                    />
                    <div style={{ backgroundColor: "#e6e6e6", padding: "10px" }}>
                        <Button variant="contained" color="primary" component="label">
                            Upload CSV
                            <input
                                style={{ display: "none" }}
                                type="file"
                                onChange={e => this.setFile(e.currentTarget.files[0])}
                            />
                        </Button>
                    </div>
                </div>
                {presentation && <div style={{ backgroundColor: "#e6e6e6", color: "#333", padding: "10px" }}><h2>{presentation.name}</h2></div>}
                <div style={{ display: "grid", gridTemplateColumns: "75% 25%" }}>
                    <div>
                        {errorMessage && <div style={{ backgroundColor: "#e6e6e6", padding: "10px" }}>{errorMessage}</div>}
                        {thumbnails && thumbnails.map(id => <QuickThumbnail key={id} slideId={id} selectedPresentation={selectedPresentation} />)}
                        {!showAll && sips && sips.length > 9 && <Button variant="contained" color="primary" onClick={e => this.setState({ showAll: true })}>Load All</Button>}
                    </div>
                    <div style={{ backgroundColor: "#e6e6e6", overflowY: "scroll", height: "100vh" }}>{presentations &&
                        (<List component="nav" aria-label="main mailbox folders">
                            {presentations.map(id => (<ListItem
                                key={id}
                                button
                                selected={selectedPresentation === id}
                                onClick={e => this.getPresentation(id)}
                            >
                                <ListItemText primary={id} />
                            </ListItem>))}
                        </List>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default withFirebaseUser(withTheme(withStyles(pageStyles)(QuickPlayer)));
