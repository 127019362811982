import React, { Component } from "reactn";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Input, Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { blockStyles } from "./styles/block_styles";
import theme from "js/react/materialThemeOverrides";
import { ThemeProvider as MuiThemeProvider, withStyles, withTheme, createTheme as createMuiTheme } from "@material-ui/core/styles";
const { grey } = theme.palette;

const now = new Date();
const defaultValue = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}T12:00`;

export const customTheme = createMuiTheme({
    ...theme.palette,
    palette: {
        primary: {
            ...theme.palette.primary,
            main: grey[400]
        }
    }
});

class MultiInputButtonBlock extends Component {
    state = {
        inputValues: {}
    };

    handleInputChanged = event => {
        const { id, value } = event.target;
        this.setState(({ inputValues }) => ({ inputValues: { ...inputValues, [id]: value } }));
    };

    handleSelectChanged = (key, event) => {
        this.setState(({ inputValues }) => ({ inputValues: { ...inputValues, [key]: event.target.value } }));
    }

    handleClick = () => {
        const { handleClick, clearOnClick } = this.props;
        const { inputValues } = this.state;

        (async () => {
            const shouldClean = await handleClick({ ...inputValues }).catch(() => { });
            if (clearOnClick || shouldClean) {
                this.setState({ inputValues: {} });
            }
        })();
    };

    render() {
        const {
            children,
            classes,
            buttonLabel,
            description,
            title,
            inputs
        } = this.props;

        const { inputValues } = this.state;

        const getInput = config => {
            switch (config.type) {
                case "datetime":
                    return (<MuiThemeProvider theme={customTheme}>
                        <TextField
                            id={config.key}
                            label={config.placeholder}
                            type="datetime-local"
                            defaultValue={defaultValue}
                            InputLabelProps={{ shrink: true }}
                            className={classes.datetime}
                            onChange={this.handleInputChanged}
                        />
                    </MuiThemeProvider>);
                case "select":
                    return (<MuiThemeProvider theme={customTheme}>
                        <FormControl style={{ width: "100%", marginTop: -18 }}>
                            <InputLabel id={config.key}>{config.placeholder}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={inputValues[config.key] ?? ""}
                                onChange={event => this.handleSelectChanged(config.key, event)}
                            >
                                {config.values.map(({ value, placeholder }) => <MenuItem key={value} value={value}>{placeholder}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </MuiThemeProvider>);
                default:
                    return (<Input
                        id={config.key}
                        onChange={this.handleInputChanged}
                        onKeyUp={e => {
                            const value = inputValues[config.key] ?? "";
                            if (
                                e.key === "Enter" &&
                                value.trim() !== ""
                            ) {
                                this.handleClick();
                            }
                        }}
                        placeholder={config.placeholder}
                        style={{
                            borderColor: grey[900],
                            color: grey[900],
                            flexGrow: "1",
                            fontFamily: "sans-serif",
                            marginRight: "10px",
                            width: "100%"
                        }}
                        value={inputValues[config.key] ?? ""}
                        type={config.type}
                    />);
            }
        };

        return (
            <div
                className={classNames({
                    [classes.block]: true,
                    [classes.expandedBlock]: !!children
                })}
            >
                <div>
                    <h1>{title}</h1>
                    <div>{description}</div>
                </div>
                <div className={classes.multiInputSection}>
                    {inputs.map(config => {
                        return (
                            <div
                                className={classes.inputContainer}
                                key={config.key}
                            >
                                {getInput(config)}
                            </div>
                        );
                    })}
                    <Button
                        onClick={this.handleClick}
                        variant="outlined"
                        color="default"
                    >
                        {buttonLabel}
                    </Button>
                </div>
                {children}
            </div>
        );
    }
}

MultiInputButtonBlock.propTypes = {
    clearOnClick: PropTypes.bool,
    classes: PropTypes.object,
    buttonLabel: PropTypes.string,
    description: PropTypes.string,
    handleClick: PropTypes.func,
    title: PropTypes.string,
    placeholder: PropTypes.string
};

export default withTheme(withStyles(blockStyles)(MultiInputButtonBlock));
