import { GlobalStateController } from "bai-react-global-state";

import getLogger, { LogGroup } from "js/core/logger";
import { EmailTypes, EmailChartTypes, EmailChartData, IEmailDeliveryLog } from "common/interfaces";
import { fetchWithAuth } from "../utils/fetchWithAuth";

const logger = getLogger(LogGroup.MANAGE_EMAILS);

interface State {
    isInitialized: boolean;
    isFetching: boolean;
    emailDeliveryLogs: IEmailDeliveryLog[];
    emailChartData: { [chartType: string]: EmailChartData };
}

const initialState: State = {
    isInitialized: false,
    isFetching: false,
    emailDeliveryLogs: [],
    emailChartData: {}
};

class ManageEmailsController extends GlobalStateController<State> {
    _firebaseUser: Record<string, any>;
    _handleShowDialog: Function;

    async initialize(firebaseUser: Record<string, any>, handleShowDialog: Function) {
        this._firebaseUser = firebaseUser;
        this._handleShowDialog = handleShowDialog;

        await this.fetchEmailDeliveryLogs();
        await Promise.all(Object.values(EmailChartTypes).map(chartType => this.fetchChartData(chartType)));

        await this._updateState({ isInitialized: true });
    }

    async fetchEmailDeliveryLogs(emailType?: EmailTypes, userIdOrEmail?: string) {
        await this._updateState({ isFetching: true });

        try {
            const emailDeliveryLogs: IEmailDeliveryLog[] = await fetchWithAuth("/admin/email-delivery-logs", {
                method: "POST",
                body: JSON.stringify({
                    emailType,
                    userIdOrEmail
                })
            }, this._firebaseUser);

            await this._updateState({ emailDeliveryLogs });
        } catch (err) {
            logger.error(err, "[ManageEmailsController] fetchEmailDeliveryLogs() failed");

            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            await this._updateState({ isFetching: false });
        }
    }

    async fetchChartData(chartType: EmailChartTypes) {
        await this._updateState({ isFetching: true });

        try {
            const chartData: EmailChartData = await fetchWithAuth("/admin/email-delivery-logs", {
                method: "POST",
                body: JSON.stringify({
                    chartType,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                })
            }, this._firebaseUser);

            const emailChartData = this._state.emailChartData;
            await this._updateState({ emailChartData: { ...emailChartData, [chartType]: chartData } });
        } catch (err) {
            logger.error(err, "[ManageEmailsController] fetchChartData() failed");

            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            await this._updateState({ isFetching: false });
        }
    }
}

export default new ManageEmailsController(initialState);
