import React, { Component } from "reactn";
import PropTypes from "prop-types";
import { withStyles, withTheme } from "@material-ui/core/styles";

import { pageStyles } from "../styles/page_styles";
import TableReport from "../components/TableReport";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

class Manage extends Component {
    getAdmins = async () => {
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/admins", {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`
            }
        });
        if (!response.ok) {
            return [
                { id: "Error " + response.status, email: "" }
            ];
        }
        return await response.json();
    };

    deleteAdmin = async userId => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/admins", {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userId })
        });
        if (!response.ok) {
            handleShowDialog(
                "Delete admin failed",
                `Error: ${response.status} ${response.statusText}`
            );
            return null;
        }
        return await response.json();
    };

    getFreeloaders = async () => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/freeloaders", {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`
            }
        });
        if (!response.ok) {
            return [
                { id: "Error " + response.status, email: "" }
            ];
        }
        return await response.json();
    };

    deleteFreeloader = async userId => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/freeloaders", {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userId })
        });
        if (!response.ok) {
            handleShowDialog(
                "Delete freeloader failed",
                `Error: ${response.status} ${response.statusText}`
            );
            return null;
        }
        return await response.json();
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.page}>
                <h2 className={classes.pageTitle}>Manage Users</h2>
                <div className={classes.blockGrid}>
                    <TableReport
                        handleShowDialog={this.props.handleShowDialog}
                        fetchOnMount={this.getAdmins}
                        bodyParamName="userEmailOrUid"
                        requestURL="/admin/admins"
                        columns={[
                            {
                                Header: "User Id",
                                accessor: "uid"
                            },
                            {
                                Header: "Email",
                                accessor: "email"
                            },
                            {
                                Cell: props => (
                                    <button onClick={() => this.deleteAdmin(props.value.uid)}>
                                        Delete Admin
                                    </button>
                                ),
                                Header: "Remove",
                                accessor: "",
                                filterable: false
                            }
                        ]}
                        description="List of current admins."
                        title="All admins"
                        buttonLabel="Add admin"
                        placeholder="User ID or Email"
                        tableProps={{
                            filterable: true
                        }}
                    />
                    <TableReport
                        handleShowDialog={this.props.handleShowDialog}
                        fetchOnMount={this.getFreeloaders}
                        bodyParamName="userEmailOrUid"
                        requestURL="/admin/freeloaders"
                        columns={[
                            {
                                Header: "User Id",
                                accessor: "id"
                            },
                            {
                                Header: "Email",
                                accessor: "email"
                            },
                            {
                                Cell: props => (
                                    <button onClick={() => this.deleteFreeloader(props.value.id)}>
                                        Delete Freeloader
                                    </button>
                                ),
                                Header: "Remove",
                                accessor: "",
                                filterable: false
                            }
                        ]}
                        description="List of current freeloaders."
                        title="All freeloaders"
                        buttonLabel="Add freeloader"
                        placeholder="User ID or Email"
                        tableProps={{
                            filterable: true
                        }}
                    />
                </div>
            </div>
        );
    }
}

Manage.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withFirebaseUser((withTheme(withStyles(pageStyles)(Manage))));
