import { GlobalStateController } from "bai-react-global-state";

import getLogger, { LogGroup } from "js/core/logger";
import { fetchWithAuth } from "js/react/views/Admin/utils/fetchWithAuth";
import type { IMongoMigration, SupportedFirebaseTables } from "common/interfaces/models";

const logger = getLogger(LogGroup.MONGO_MIGRATION);

interface State {
    isInitialized: boolean;
    isFetching: boolean;
    isSyncing: boolean;
    mongoMigrationEntries: IMongoMigration[];
}

const initialState: State = {
    isInitialized: false,
    isFetching: false,
    isSyncing: false,
    mongoMigrationEntries: [],
};

class MongoMigrationController extends GlobalStateController<State> {
    _firebaseUser: Record<string, any>;
    _handleShowDialog: Function;

    async initialize(firebaseUser: Record<string, any>, handleShowDialog: Function) {
        this._firebaseUser = firebaseUser;
        this._handleShowDialog = handleShowDialog;

        await this.fetchMongoMigrationEntries();

        await this._updateState({ isInitialized: true });
    }

    async fetchMongoMigrationEntries(firebaseTable?: SupportedFirebaseTables, firebaseDocumentId?: string) {
        await this._updateState({ isFetching: true });

        try {
            const mongoMigrationEntries: IMongoMigration[] = await fetchWithAuth("/admin/mongo-migration", {
                method: "POST",
                body: JSON.stringify({
                    firebaseTable,
                    firebaseDocumentId
                })
            }, this._firebaseUser);

            await this._updateState({ mongoMigrationEntries });
        } catch (err) {
            logger.error(err, "[MongoMigrationController] fetchMongoMigrationEntries() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            await this._updateState({ isFetching: false });
        }
    }

    async triggerManualSync(firebaseTable: SupportedFirebaseTables, firebaseDocumentId: string) {
        await this._updateState({ isSyncing: true });

        try {
            await fetchWithAuth("/admin/mongo-migration", {
                method: "POST",
                body: JSON.stringify({
                    firebaseTable,
                    firebaseDocumentId,
                    triggerSync: true
                })
            }, this._firebaseUser);

            this._handleShowDialog("Success", `Sync triggered for ${firebaseTable} ${firebaseDocumentId}`);
        } catch (err) {
            logger.error(err, "[MongoMigrationController] triggerManualSync() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            await this._updateState({ isSyncing: false });
        }
    }
}

export default new MongoMigrationController(initialState);
