import React from "react";
import ReactDOM from "react-dom";

//
// entry point
//

// css
import "css/admin.scss";

const root = document.createElement("div");
root.id = "root";
root.style.display = "contents";
document.body.appendChild(root);

(async () => {
    const { init } = await import(/* webpackMode: "eager" */ "js/firebase/init");
    await init();

    await import(/* webpackMode: "eager" */ "js/preload");

    const { default: Adapter } = await import(/* webpackMode: "eager" */ "js/core/storage/adapter");
    const { default: FirebaseAdapter } = await import(/* webpackMode: "eager" */ "js/core/storage/firebaseAdapter");
    Adapter.setDefaultClass(FirebaseAdapter);

    const { default: AdminApp } = await import(/* webpackMode: "eager" */ "js/react/views/Admin/adminApp");

    ReactDOM.render(<AdminApp appVersion={window.appVersion} />, root);
})();

