import React from "react";

import { NumericInput as BpNumericInput, FormGroup, InputGroup, Switch, TextArea } from "@blueprintjs/core";

export const TextInput = ({ disabled, label, onChange, value }) => (<FormGroup
    disabled={disabled}
    label={label}
>
    <InputGroup
        fill
        placeholder={label}
        disabled={disabled}
        value={value}
        onChange={event => onChange(event.target.value)}
    />
</FormGroup>);

export const MultilineTextInput = ({ disabled, label, onChange, value }) => (<FormGroup
    disabled={disabled}
    label={label}
>
    <TextArea
        fill
        placeholder={label}
        disabled={disabled}
        value={value}
        growVertically
        onChange={event => onChange(event.target.value)}
    />
</FormGroup>);

export const NumericInput = ({ label, onChange, value, minValue = 0, disabled = false }) => (<FormGroup
    disabled={disabled}
    label={label}
>
    <BpNumericInput
        fill
        placeholder={label}
        disabled={disabled}
        value={value}
        min={minValue}
        onValueChange={onChange}
    />
</FormGroup>);

export const SwitchInput = ({ label, onChange, value, values, showInnerLabel = true, disabled = false }) => (<FormGroup
    disabled={disabled}
    label={label}
>
    <Switch
        disabled={disabled}
        labelElement={showInnerLabel ? "" : label}
        innerLabelChecked={showInnerLabel ? values[0] : undefined}
        innerLabel={showInnerLabel ? values[1] : undefined}
        checked={value === values[0]}
        onChange={event => onChange(event.target.checked ? values[0] : values[1])}
    />
</FormGroup>);
