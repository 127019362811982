import React, { useEffect } from "react";
import styled from "styled-components";
import "../../styles/blueprint_styles";

import FetchingClickShield from "js/react/components/FetchingClickShield";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import ProvisioningController from "js/react/views/Admin/controllers/ProvisioningController";

import SourcesTable from "./SourcesTable";
import SourceForm from "./SourceForm";

const PageContainer = styled.div`
    position: relative;
    background: #e6e6e6;
    display: flex;
    flex-flow: column;
    min-height: calc(100vh - 58px);
    width: 100%;

    // Global blueprint styles
    .bp4-label,.bp4-switch {
        color: #989898;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: flex-start;

    width: 100%;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 20px;
    color: #2d2d2d;
    font-size: 1.5em;

    padding: 20px;
`;

const ControlsContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const ControlContainer = styled.div`
    padding: 10px;
    margin: 10px 0 10px 10px;
    border: 1px solid #d6d6d6;
`;

function Provisioning({ isInitialized, firebaseUser, handleShowDialog }) {
    useEffect(() => {
        ProvisioningController.initialize(firebaseUser, handleShowDialog);
    }, []);

    if (!isInitialized) {
        return (<PageContainer><FetchingClickShield visible backgroundColor="#e6e6e6" /> </PageContainer>);
    }

    return (<PageContainer>
        <HeaderContainer>User Provisioning Services</HeaderContainer>

        <ControlsContainer>
            <ControlContainer>
                <SourceForm />
            </ControlContainer>
        </ControlsContainer>

        <SourcesTable />
    </PageContainer>);
}

export default withFirebaseUser(ProvisioningController.withState(Provisioning));
