import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, FormGroup, HTMLSelect } from "@blueprintjs/core";

import { emailRegex } from "js/core/utilities/regex";
import EnterpriseController from "js/react/views/Admin/controllers/EnterpriseController";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

import { NumericInput, SwitchInput, TextInput } from "../../components/Inputs";

const Container = styled.div`
    >p {
        font-size: 18px;
        color: #2d2d2d;
        margin-bottom: 5px;
    }    
`;

const ContentContainer = styled.div`
    display: flex;
    flex-flow: row;
    gap: 10px;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 10px;

    >.bp4-form-group {
        margin: 0;
    } 

    >.bp4-button {
        margin-top: 23px;
        width: 100%;
    }
`;

function CreateInviteForm({ fetching, firebaseUser, admins, prices }) {
    const defaultFormValues = {
        inviteeEmail: "",
        pricePerSeat: 480,
        seatCount: 1,
        billingInterval: "year",
        trialDurationDays: 14,
        daysUntilInvoiceDue: 30,
        notifyInviter: true,
        invitedBy: firebaseUser.uid,
        teamName: "",
        priceId: undefined
    };

    const [formValues, setFormValues] = useState({ ...defaultFormValues });
    const [isFormValid, setFormValid] = useState("");

    useEffect(() => {
        const price = prices[0];
        if (!price) {
            return;
        }

        const formUpdates = {
            priceId: price.id,
            pricePerSeat: price.amount / 100,
            billingInterval: price.billingInterval
        };
        setFormValues({ ...formValues, ...formUpdates });
    }, []);

    useEffect(() => {
        setFormValid(emailRegex.test(formValues.inviteeEmail));
    }, [Object.values(formValues).join("")]);

    const enrichedPrices = prices
        .map(price => ({
            ...price,
            amountString: `\$ ${(price.amount / 100).toFixed(2)} / ${price.billingInterval}${price.isDefault ? " (default)" : ""}`
        }));

    const aeEmail = admins.find(({ uid }) => uid === formValues.invitedBy)?.email;

    const selectedPrice = enrichedPrices.find(({ id }) => id === formValues.priceId);

    const handleCreateInvite = async () => {
        const invited = await EnterpriseController.createInvite(formValues);
        if (invited) {
            setFormValues({ ...defaultFormValues });
        }
    };

    const handleFormValueChange = formKey => value => setFormValues({ ...formValues, [formKey]: value });

    const handleSetInvitedBy = aeEmail => {
        const uid = admins.find(({ email }) => email === aeEmail)?.uid;
        handleFormValueChange("invitedBy")(uid);
    };

    const handleSetPriceId = priceString => {
        const price = enrichedPrices.find(({ amountString }) => amountString === priceString);
        const formUpdates = {
            priceId: price?.id
        };
        if (price) {
            formUpdates.pricePerSeat = price.amount / 100;
            formUpdates.billingInterval = price.billingInterval;
        }
        setFormValues({ ...formValues, ...formUpdates });
    };

    return (<Container>
        <p>Create new invite</p>
        <ContentContainer>
            <InputsContainer>
                <TextInput
                    label="Email"
                    value={formValues.inviteeEmail}
                    onChange={handleFormValueChange("inviteeEmail")}
                    disabled={fetching}
                />
                <FormGroup label="Price" disabled={fetching}>
                    <HTMLSelect
                        fill
                        disabled={fetching}
                        options={[...enrichedPrices.map(({ amountString }) => amountString), "Custom..."]}
                        value={selectedPrice?.amountString}
                        onChange={event => handleSetPriceId(event.target.value)}
                    />
                </FormGroup>
                <NumericInput
                    label="Price per seat $"
                    value={formValues.pricePerSeat}
                    disabled={!!selectedPrice}
                    // https://blueprintjs.com/docs/#core/components/numeric-input
                    // comment based on their docs
                    // When handling changes in controlled mode, always use the second parameter of the onValueChange callback,
                    // which provides the value as a string. This allows users to type non-numeric characters like decimal points (".")
                    // without the component eagerly coercing those strings to their parsed numeric equivalents (0. becomes 0, fractional data entry impossible).
                    onChange={(priceAsNumber, priceAsString) => handleFormValueChange("pricePerSeat")(priceAsString)}
                />
                <SwitchInput
                    label="Billing interval"
                    value={formValues.billingInterval}
                    values={["year", "month"]}
                    disabled={!!selectedPrice}
                    onChange={handleFormValueChange("billingInterval")}
                />
                <NumericInput
                    label="Seat count"
                    value={formValues.seatCount}
                    onChange={handleFormValueChange("seatCount")}
                    disabled={fetching}
                />
            </InputsContainer>
            <InputsContainer>
                <TextInput
                    label="Team name (optional)"
                    value={formValues.teamName}
                    onChange={handleFormValueChange("teamName")}
                    disabled={fetching}
                />
                <NumericInput
                    label="Days until invoice is due"
                    value={formValues.daysUntilInvoiceDue}
                    onChange={handleFormValueChange("daysUntilInvoiceDue")}
                    disabled={fetching}
                />
                <NumericInput
                    label="Tiral duration (days)"
                    value={formValues.trialDurationDays}
                    onChange={handleFormValueChange("trialDurationDays")}
                    disabled={fetching}
                />
                <FormGroup label="Assigned AE" disabled={fetching}>
                    <HTMLSelect
                        fill
                        disabled={fetching}
                        options={admins.map(({ email }) => email)}
                        value={aeEmail}
                        onChange={event => handleSetInvitedBy(event.target.value)}
                    />
                </FormGroup>
                <SwitchInput
                    label="Notify AE about invite events"
                    value={formValues.notifyInviter}
                    values={[true, false]}
                    onChange={handleFormValueChange("notifyInviter")}
                    showInnerLabel={false}
                    disabled={fetching}
                />
            </InputsContainer>
            <Button
                rightIcon="arrow-right"
                intent="success"
                text="Create"
                disabled={!isFormValid || fetching}
                loading={fetching}
                onClick={handleCreateInvite}
            />
        </ContentContainer>
    </Container>);
}

export default withFirebaseUser(EnterpriseController.withState(CreateInviteForm));
