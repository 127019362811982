import React from "react";
import styled from "styled-components";

import { FormGroup, Button } from "@blueprintjs/core";

import EnterpriseController from "js/react/views/Admin/controllers/EnterpriseController";

const ManageNotificationsFormContainer = styled.div`
    >p {
        font-size: 18px;
        color: #2d2d2d;
        margin-bottom: 5px;
    }

    >.bp4-form-group {
        margin-bottom: 4px;

        >.bp4-form-content {
            >.bp4-button {
                width: 100%;
            }
        }
    } 
`;

function ManageNotificationsForm({ fetching, userAttributes }) {
    return (<ManageNotificationsFormContainer>
        <p>Notifications</p>
        <FormGroup
            disabled={fetching}
            label="Slack integration"
            helperText={userAttributes.isSlackEnabled ? "Slack integration enabled" : "Enable Slack integration to receive notifications about invites"}
        >
            <Button rightIcon="send-message" intent="success" text="Enable" disabled={fetching || userAttributes.isSlackEnabled} loading={fetching} onClick={() => EnterpriseController.enableSlack()} />
        </FormGroup>
    </ManageNotificationsFormContainer >);
}

export default EnterpriseController.withState(ManageNotificationsForm);
