import React, { Fragment } from "react";
import styled from "styled-components";
import moment from "moment";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import FetchingClickShield from "js/react/components/FetchingClickShield";

import ManageAIController, { ManageAIControllerState } from "js/react/views/Admin/controllers/ManageAIController";

const Container = styled.div`
    position: relative;
    margin: 10px 0 10px 10px;
    border: 1px solid #d6d6d6;
    width: calc(100% - 20px);
    padding: 10px;
`;

const Header = styled.div`
    margin: 10px 0 10px 0;
    font-size: 20px;
    color: #333;
    text-align: center;
`;

const ReportsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(8, min-content);
    white-space: nowrap;
    color: #333;

    >div {
        padding: 5px 10px;
        border-bottom: 1px solid #bbb;
    }
`;

const HeaderCell = styled.div`
    font-size: 14px;
    padding: 5px 10px;
`;

const Cell = styled.div`
    display: flex;
    flex-direction: column;
`;

const TokensReportCell = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 10px;
`;

function ReportByPresentations({ reportsByPresentations, fetching }: ManageAIControllerState) {
    return (<Container>
        <FetchingClickShield visible={fetching} backgroundColor="rgba(230,230,230,0.15)" />
        <Header>
            Latest presentation generation requests
        </Header>
        <ReportsContainer>
            <HeaderCell>
                Date and user
            </HeaderCell>
            <HeaderCell>
                Total cost
            </HeaderCell>
            <HeaderCell>
                Outline cost
            </HeaderCell>
            <HeaderCell>
                Slides cost
            </HeaderCell>
            <HeaderCell>
                Slides count
            </HeaderCell>
            <HeaderCell>
                Total tokens
            </HeaderCell>
            <HeaderCell>
                Outline tokens
            </HeaderCell>
            <HeaderCell>
                Slides tokens
            </HeaderCell>
            {reportsByPresentations
                .map((report, idx) => (<Fragment key={idx}>
                    <Cell>
                        {moment(report.createdAt).format("YYYY-MM-DD HH:mm:ss")} from {report.userEmail}
                    </Cell>
                    <Cell>
                        <b>${((report.outline.models.reduce((totalCost, model) => model.totalCost + totalCost, 0)) + report.slides.models.reduce((totalCost, model) => model.totalCost + totalCost, 0)).toFixed(3)}</b>
                    </Cell>
                    <Cell>
                        <b>${(report.outline.models.reduce((totalCost, model) => model.totalCost + totalCost, 0)).toFixed(3)}</b>
                    </Cell>
                    <Cell>
                        <b>${(report.slides.models.reduce((totalCost, model) => model.totalCost + totalCost, 0)).toFixed(3)}</b>
                    </Cell>
                    <Cell>
                        <b>{report.slides.slideCount}</b>
                    </Cell>
                    <TokensReportCell>
                        {report.models.map((model, index) => (<div key={index}>
                            <b>{model.model}</b>: <b>{model.promptTokenCount + model.completionTokenCount}</b> ({model.promptTokenCount} / {model.completionTokenCount}) (${model.totalCost.toFixed(3)})
                        </div>))}
                    </TokensReportCell>
                    <TokensReportCell>
                        {report.outline.models.map((model, index) => (<div key={index}>
                            <b>{model.model}</b>: <b>{model.promptTokenCount + model.completionTokenCount}</b> ({model.promptTokenCount} / {model.completionTokenCount}) (${model.totalCost.toFixed(3)})
                        </div>))}
                    </TokensReportCell>
                    <TokensReportCell>
                        {report.slides.models.map((model, index) => (<div key={index}>
                            <b>{model.model}</b>: <b>{model.promptTokenCount + model.completionTokenCount}</b> ({model.promptTokenCount} / {model.completionTokenCount}) (${model.totalCost.toFixed(3)})
                        </div>))}
                    </TokensReportCell>
                </Fragment>))}
        </ReportsContainer>
    </Container >);
}

export default ManageAIController.withState(
    ReportByPresentations
);
