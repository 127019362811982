import { GlobalStateController } from "bai-react-global-state";

import getLogger, { LogGroup } from "js/core/logger";
import { fetchWithAuth } from "js/react/views/Admin/utils/fetchWithAuth";
import { AiTokenUsageReportByModelsRecord, AiTokenUsageReportByModelsType, AiTokenUsageReportByPresentationsRecord } from "common/aiConstants";

const logger = getLogger(LogGroup.AI);

export interface ManageAIControllerState {
    isInitialized: boolean
    fetching: boolean
    reportsByModels?: Record<AiTokenUsageReportByModelsType, AiTokenUsageReportByModelsRecord>
    reportsByPresentations?: AiTokenUsageReportByPresentationsRecord[]
}

const initialState: ManageAIControllerState = {
    isInitialized: false,
    fetching: true
};

class ManageAIController extends GlobalStateController<ManageAIControllerState> {
    private _firebaseUser: null | Record<string, any>;
    private _handleShowDialog: null | ((title: string, body: string, props?: Record<string, any>) => void);

    public async initialize(firebaseUser, handleShowDialog) {
        this._firebaseUser = firebaseUser;
        this._handleShowDialog = handleShowDialog;

        await this.fetchReports(false);

        await this._updateState({ isInitialized: true });
    }

    public async fetchReports(handleErrors: boolean = true) {
        this._updateState({ fetching: true });

        try {
            await Promise.all([
                this._fetchReportsByModels(handleErrors),
                this._fetchReportsByPresentations(handleErrors)
            ]);
        } catch (err) {
            if (!handleErrors) {
                throw err;
            }
            logger.error(err, "[ManageAIController] fetchReports() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            await this._updateState({ fetching: false });
        }
    }

    private async _fetchReportsByModels(handleErrors: boolean = true) {
        const reports = await fetchWithAuth(`/admin/aiTokenUsageByModels?timezone=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}`, { method: "GET" }, this._firebaseUser);
        await this._updateState({ reportsByModels: (reports as Record<AiTokenUsageReportByModelsType, AiTokenUsageReportByModelsRecord>) });
    }

    private async _fetchReportsByPresentations(handleErrors: boolean = true) {
        const reports = await fetchWithAuth("/admin/aiTokenUsageByPresentations", { method: "GET" }, this._firebaseUser);
        await this._updateState({ reportsByPresentations: (reports as AiTokenUsageReportByPresentationsRecord[]) });
    }
}

export default new ManageAIController(initialState);
