import theme from "js/react/materialThemeOverrides";
const { primary, grey } = theme.palette;

export const appStyles = {
    root: {
        backgroundColor: grey[800],
        fontFamily: "sans-serif",
        color: grey[100],
        width: "100%",
        minHeight: "100%"
    },
};
