import React, { Component } from "reactn";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles, withTheme } from "@material-ui/core/styles";

import { pageStyles } from "../styles/page_styles";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

const LinkWrapper = styled.div`
    >a {
        font-size: 18px;
        font-weight: 600;
        color: #ddd;
        text-decoration: none;
    }

    margin-bottom: 10px;
`;

class Home extends Component {
    render() {
        const { classes, navOptions } = this.props;

        return (
            <div className={classes.page}>
                <h2 className={classes.pageTitle}>Welcome to B.ai admin UI! Navigate the the page you need.</h2>
                <div>
                    {navOptions.map(option => (
                        <LinkWrapper key={option.name}>
                            <Link
                                exact={option.fragment === ""}
                                to={option.fragment}
                            >
                                {option.name}
                            </Link>
                        </LinkWrapper>
                    ))}
                </div>
            </div>
        );
    }
}

Home.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    navOptions: PropTypes.array.isRequired
};

export default withFirebaseUser((withTheme(withStyles(pageStyles)(Home))));
