import React from "react";
import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    Button
} from "@material-ui/core";
import type { DialogProps, ButtonProps } from "@material-ui/core";

interface Props extends Partial<DialogProps> {
    okButtonLabel?: string;
    cancelButtonLabel?: string;
    closeDialog?: Function;
    onAccept?: Function;
    onCancel?: Function;
}

export default function AlertDialog(props: Props) {
    const {
        title,
        open = true,
        cancelButtonLabel,
        okButtonLabel = "Ok",
        closeDialog = () => { },
        onAccept = () => { },
        onCancel = () => { },
        ...dialogProps
    } = props;

    const handleAccept = () => {
        onAccept();
        closeDialog();
    };

    const handleCancel = () => {
        onCancel();
        closeDialog();
    };

    return (
        <Dialog open={open} onClose={handleCancel as DialogProps["onClose"]} {...dialogProps}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className="dialog-content-text">
                <DialogContentText>{props.children}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {cancelButtonLabel &&
                    <Button onClick={handleCancel as ButtonProps["onClick"]} color="primary">
                        {cancelButtonLabel}
                    </Button>
                }
                <Button onClick={handleAccept as ButtonProps["onClick"]} color="primary">
                    {okButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
