import React, { Component } from "reactn";
import PropTypes from "prop-types";
import "react-table/react-table.css";
import { withStyles, withTheme } from "@material-ui/core/styles";
import InputButtonBlock from "./InputButtonBlock";
import { blockStyles } from "./styles/block_styles";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

class ReportBlock extends Component {
    state = {
        queryResults: null
    };

    componentDidMount() {
        const { fetchOnMount } = this.props;
        if (fetchOnMount) {
            fetchOnMount().then(queryResults => {
                this.setState({
                    queryResults
                });
            });
        }
    }

    handleClick = async param => {
        const { requestURL, bodyParamName, handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch(requestURL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ [bodyParamName]: param })
        });
        const results = await response.json();
        if (!response.ok) {
            handleShowDialog(
                results.code || "An error has occurred.",
                results.message || "Please check the logs for more information."
            );
            return null;
        }
        this.setState({
            queryResults: results
        });
    };

    render() {
        const {
            classes,
            description,
            placeholder,
            title,
            render,
            buttonLabel
        } = this.props;

        const { queryResults } = this.state;

        return (
            <InputButtonBlock
                buttonLabel={buttonLabel || "Generate Report"}
                clearOnClick={true}
                description={description}
                handleClick={this.handleClick}
                placeholder={placeholder}
                title={title}
            >
                {queryResults && (
                    <div className={classes.resultsSection}>
                        {render(queryResults)}
                    </div>
                )}
            </InputButtonBlock>
        );
    }
}

ReportBlock.propTypes = {
    bodyParamName: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    requestURL: PropTypes.string.isRequired,
    tableProps: PropTypes.object,
    title: PropTypes.string.isRequired
};

export default withFirebaseUser(withTheme(withStyles(blockStyles)(ReportBlock)));
