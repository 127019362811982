import React from "react";
import JSONPretty from "react-json-pretty";
import styled from "styled-components";
import moment from "moment";
import { Button, Spinner } from "@blueprintjs/core";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
highchartsMore(Highcharts);

import { tinycolor, _ } from "js/vendor";
import { PREDEFINED_PALETTES } from "common/constants";
import { EmailTypes, EmailChartTypes } from "common/interfaces";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

import ManageEmailsController from "../controllers/ManageEmailsController";

const Container = styled.div`
    background: #e6e6e6;
    display: flex;
    flex-flow: column;
    width: 100%;
    color: #2d2d2d;
    max-height: calc(100vh - 58px);
    min-height: calc(100vh - 58px);
    overflow-y: auto;
    & .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #c2c2c2;
        padding: 20px;
        padding-bottom: 20px;
        font-size: 1.5em;
    }
    & .content {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid #c2c2c2;
        padding: 20px;
        gap: 20px;
        & .content-card {
            background: white;
            border: 1px solid #d6d6d6;
            border-radius: 3px;
            padding: 20px;
            gap: 10px;
            display: flex;
            flex-flow: column;
            min-width: 500px;
            width: 100%;
        }
    }
    & .table {
        display: flex;
        flex-flow: column;
        padding: 20px;     
        width: 100%;
        & .table-row {
            background: white;
            cursor: pointer;
            border: 1px solid #d6d6d6;
            border-radius: 3px;
            padding: 20px;
            gap: 30px;
            display: flex;
            flex-flow: row wrap;
            & .table-row-data:first-child {
                width: 20%;
            }
            & .table-row-data:nth-child(2) {
                width: 40%;
            }
            & .table-row-expanded {
                margin-top: -20px;
                display: flex;
                flex-flow: row;
                min-height: 200px;
                width: 100%;
                gap: 20px;
                & .table-row-json-view {
                    background: #e6e6e6;
                    border: 1px solid #d6d6d6;
                    width: 100%;
                    overflow-x: auto;
                }
            }
        }
        & .table-empty-text {
            text-align: center;
            padding: 100px;
            color: #989898;
            font-size: 1.5em;
        }
    }
    & .chart {
        width: calc(100% - 16px);
        >div {
            overflow: visible !important;
        }
    }
`;

class ManageEmails extends React.Component {
    state = {
        selectedView: "charts",
        selectedEmailType: "all",
        selectedUserIdOrEmail: "",
        emailDeliveryLogs: [],
        expandedEmailDeliveryLogIds: [],
    };

    componentDidMount() {
        const { firebaseUser, handleShowDialog } = this.props;
        ManageEmailsController.initialize(firebaseUser, handleShowDialog);
    }

    handleFilter = () => {
        const { selectedEmailType, selectedUserIdOrEmail } = this.state;
        ManageEmailsController.fetchEmailDeliveryLogs(selectedEmailType, selectedUserIdOrEmail);
    };

    handleExpandMigrationEntry = id => {
        const { expandedEmailDeliveryLogIds } = this.state;
        const newExpandedEmailDeliveryLogIds = expandedEmailDeliveryLogIds.includes(id)
            ? expandedEmailDeliveryLogIds.filter(entryId => entryId !== id)
            : [...expandedEmailDeliveryLogIds, id];
        this.setState({ expandedEmailDeliveryLogIds: newExpandedEmailDeliveryLogIds });
    };

    handleSelectedView = async selectedView => {
        if (selectedView === "charts") {
            await Promise.all(Object.values(EmailChartTypes).map(chartType => ManageEmailsController.fetchChartData(chartType)));
        } else {
            await ManageEmailsController.fetchEmailDeliveryLogs();
        }
        this.setState({ selectedView });
    };

    getChartTitle(chartType) {
        switch (chartType) {
            case EmailChartTypes.EMAILS_SENT_LAST_HOUR:
                return "Emails Sent Last Hour";
            case EmailChartTypes.EMAILS_SENT_LAST_24_HOURS:
                return "Emails Sent Last 24 Hours";
            case EmailChartTypes.EMAILS_SENT_LAST_30_DAYS:
                return "Emails Sent Last 30 Days";
        }
    }

    getChartConfig(chartType, report) {
        const categories = report.data.map(({ emailDeliveryTime }) => chartType === EmailChartTypes.EMAILS_SENT_LAST_30_DAYS ? emailDeliveryTime : emailDeliveryTime.split(" ").pop());

        const series = Object.values(EmailTypes).map((emailType, seriesIdx) => {
            const data = report.data.map(({ emailDeliveryLogs }) => emailDeliveryLogs).map(logs => logs.length ? logs.reduce((sum, { emailDeliveryCount }) => sum + emailDeliveryCount, 0) : 0).map(y => ({ y }));

            const color = PREDEFINED_PALETTES[0].colors[`chart${(seriesIdx % 4) + 1}`];
            const fillColor = tinycolor(color).setAlpha(0.66).toRgbString();

            return {
                id: `series-${seriesIdx}-${chartType}`,
                name: emailType,
                type: "area",
                data,
                stacking: "normal",
                color,
                fillColor,
                lineColor: color,
                lineWidth: 3
            };
        });

        return {
            legend: { enabled: true },
            title: { text: this.getChartTitle(chartType) },
            series,
            xAxis: {
                visible: true,
                showFirstLabel: true,
                showLastLabel: true,
                endOnTick: true,
                categories,
                labelInterval: 1,
                type: "category",
                autoType: "linear",
                categoryType: "auto",
                labels: {
                    enabled: true,
                    style: {
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        color: "rgb(0, 0, 0)",
                        fontWeight: 300
                    },
                    y: 30,
                    overflow: "allow",
                    autoRotation: [-45],
                    padding: 5
                },
                zeroAxisPadding: false,
                lineWidth: 0.7,
                lineColor: "rgba(0, 0, 0, 0.3)",
                gridLineColor: "rgba(0, 0, 0, 0.1)",
                tickColor: "rgba(0, 0, 0, 0.1)",
                tickWidth: 0.5,
                gridLineWidth: 0.4,
                tickLength: 10,
                tickmarkPlacement: "on"
            },
            yAxis: [
                {
                    visible: true,
                    showFirstLabel: true,
                    showLastLabel: true,
                    endOnTick: true,
                    labels: {
                        enabled: true,
                        style: {
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            color: "rgb(0, 0, 0)",
                            fontWeight: 300
                        },
                        autoRotation: [-45],
                        padding: 5,
                    },
                    title: {
                        text: "Emails Delivered",
                    },
                    lineWidth: 0.7,
                    lineColor: "rgba(0, 0, 0, 0.3)",
                    tickColor: "rgba(0, 0, 0, 0.1)",
                    gridLineColor: "rgba(0, 0, 0, 0.1)",
                    tickWidth: 0.5,
                    gridLineWidth: 0.4,
                    tickLength: 10,
                    type: "linear",
                }
            ]
        };
    }

    renderChartsView() {
        const { emailChartData } = this.props;

        return (
            <>
                {Object.values(EmailChartTypes).map(chartType => {
                    if (!emailChartData || !emailChartData[chartType]) {
                        return <div className="content" key={chartType}><Spinner /></div>;
                    }
                    return (
                        <div key={chartType} className="content">
                            <div className="chart">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={this.getChartConfig(chartType, emailChartData[chartType])}
                                />
                            </div>
                        </div>
                    );
                })}

            </>
        );
    }

    renderFilterListView() {
        const { isFetching, emailDeliveryLogs = [] } = this.props;
        const { expandedEmailDeliveryLogIds } = this.state;

        const options = ["all", ...Object.keys(EmailTypes)].map(
            key => (<option key={key} value={key}>{key === "all" ? "All Email Types" : key}</option>)
        );

        return (
            <>
                <div className="content">
                    <div className="content-card">
                        <span>Filter Email Delivery Logs</span>
                        <div className="bp4-select">
                            <select onChange={e => this.setState({ selectedEmailType: e.target.value })}>{options}</select>
                        </div>
                        <div className="bp4-input-group">
                            <input
                                className="bp4-input"
                                placeholder="User ID or Email"
                                onChange={e => this.setState({ selectedUserIdOrEmail: e.target.value })}
                                type="text"
                            />
                        </div>
                        <button className="bp4-button bp4-intent-primary" onClick={this.handleFilter}>
                            Filter email history
                        </button>
                    </div>
                </div>
                <div className="table">
                    {isFetching && <Spinner />}
                    {!emailDeliveryLogs.length && <div className="table-empty-text">No entries found</div>}
                    {emailDeliveryLogs.map(log => {
                        const { _id: id, emailType, toUserEmail, createdAt, updatedAt } = log;
                        const isExpanded = expandedEmailDeliveryLogIds.includes(id);
                        return (
                            <div className="table-row" key={id} onClick={() => this.handleExpandMigrationEntry(id)}>
                                <span className="table-row-data"><b>{emailType}</b></span>
                                <span className="table-row-data">User Email: <i>{toUserEmail}</i></span>
                                <span className="table-row-data">Sent at: {moment(updatedAt || createdAt).format("MMM:Do HH:mm:ss")}</span>
                                <div style={{ flexGrow: 2 }} />
                                <Button icon={isExpanded ? "chevron-up" : "chevron-down"} />
                                {isExpanded && (<>
                                    <div className="table-row-expanded">
                                        <div className="table-row-json-view">
                                            <span><b>Email Delivery Log</b></span>
                                            <JSONPretty id="json-pretty" data={log} />
                                        </div>
                                    </div>
                                </>)}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }

    render() {
        const { selectedView } = this.state;

        return (
            <Container>
                <div className="header">
                    <span>Manage Emails</span>
                    <button className="bp4-button bp4-intent-primary" onClick={() => this.handleSelectedView(selectedView === "list" ? "chart" : "list")}>
                        {selectedView === "list" ? "List View" : "Charts View"}
                    </button>
                </div>
                {selectedView === "list" ? this.renderFilterListView() : this.renderChartsView()}
            </Container>
        );
    }
}

export default withFirebaseUser(ManageEmailsController.withState(ManageEmails));
