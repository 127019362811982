import React, { useEffect } from "react";
import styled from "styled-components";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

highchartsMore(Highcharts);

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import FetchingClickShield from "js/react/components/FetchingClickShield";

import { tinycolor } from "js/vendor";
import { PREDEFINED_PALETTES } from "common/constants";
import ManageAIController from "js/react/views/Admin/controllers/ManageAIController";
import { AiModel, AiTokenUsageReportByModelsType } from "common/aiConstants";

const Container = styled.div`
    position: relative;
    margin: 10px 0 10px 10px;
    border: 1px solid #d6d6d6;
    width: calc(100% - 20px);
    padding: 10px;
`;

const ChartContainer = styled.div`
    width: calc(100% - 16px);

    >div {
        overflow: visible !important;
    }
`;

const Header = styled.div`
    margin: 10px 0 10px 0;
    font-size: 20px;
    color: #333;
    text-align: center;
`;

const SummaryContainer = styled.div`
    color: #444;
    margin-bottom: 20px;
    font-family: monospace;
`;

function getChartConfig(reportType, report) {
    const categories = report.map(({ date }) => reportType === AiTokenUsageReportByModelsType.LAST_30_DAYS ? date : date.split(" ").pop());

    function formatDataLabel() {
        return `$${this.value}`;
    }

    const series = Object.values(AiModel).map((model, seriesIdx) => {
        const data = report.map(({ models }) => ({ y: models.find(({ model: m }) => m === model)?.totalCost ?? 0 }));

        const color = PREDEFINED_PALETTES[0].colors[`chart${(seriesIdx % 4) + 1}`];
        const fillColor = tinycolor(color).setAlpha(0.66).toRgbString();

        return {
            id: `series-${seriesIdx}-${reportType}`,
            name: model,
            type: "area",
            data,
            stacking: "normal",
            color,
            fillColor,
            lineColor: color,
            lineWidth: 3
        };
    });

    return {
        legend: { enabled: true },
        title: { text: null },
        series,
        xAxis: {
            visible: true,
            showFirstLabel: true,
            showLastLabel: true,
            endOnTick: true,
            categories,
            labelInterval: 1,
            type: "category",
            autoType: "linear",
            categoryType: "auto",
            labels: {
                enabled: true,
                style: {
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                    color: "rgb(0, 0, 0)",
                    fontWeight: 300
                },
                y: 30,
                overflow: "allow",
                autoRotation: [-45],
                padding: 5
            },
            zeroAxisPadding: false,
            lineWidth: 0.7,
            lineColor: "rgba(0, 0, 0, 0.3)",
            gridLineColor: "rgba(0, 0, 0, 0.1)",
            tickColor: "rgba(0, 0, 0, 0.1)",
            tickWidth: 0.5,
            gridLineWidth: 0.4,
            tickLength: 10,
            tickmarkPlacement: "on"
        },
        yAxis: [
            {
                visible: true,
                showFirstLabel: true,
                showLastLabel: true,
                endOnTick: true,
                labels: {
                    enabled: true,
                    style: {
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        color: "rgb(0, 0, 0)",
                        fontWeight: 300
                    },
                    autoRotation: [-45],
                    padding: 5,
                    formatter: formatDataLabel
                },
                title: {
                    text: "Cumulative cost",
                },
                format: "currency",
                formatOptions: {
                    currency: "$"
                },
                lineWidth: 0.7,
                lineColor: "rgba(0, 0, 0, 0.3)",
                tickColor: "rgba(0, 0, 0, 0.1)",
                gridLineColor: "rgba(0, 0, 0, 0.1)",
                tickWidth: 0.5,
                gridLineWidth: 0.4,
                tickLength: 10,
                type: "linear",
            }
        ]
    };
}

function ReportByModels({ reportType, reportsByModels, fetching }) {
    const report = reportsByModels?.find(({ type }) => type === reportType)?.report;
    if (!report) {
        return null;
    }

    const summary = report.reduce((summary, { models }) => {
        models.forEach(({ model, totalCost, tokenCount }) => {
            if (!summary[model]) {
                summary[model] = {};
            }
            summary[model].totalCost = (summary[model].totalCost ?? 0) + totalCost;
            summary[model].tokenCount = (summary[model].tokenCount ?? 0) + tokenCount;
        });
        return summary;
    }, {});

    return (<Container>
        <FetchingClickShield visible={fetching} backgroundColor="rgba(230,230,230,0.15)" />
        <Header>
            {reportType.replace(/_/g, " ")}
        </Header>
        <SummaryContainer>
            {Object.entries(summary)
                .filter(([, { totalCost }]) => !!totalCost)
                .map(([model, { totalCost, tokenCount }]) => (
                    <div key={model}>
                        <b>{model}</b>: ${totalCost.toFixed(5)} ({tokenCount} tokens)
                    </div>
                ))}
        </SummaryContainer>
        <ChartContainer>
            <HighchartsReact
                highcharts={Highcharts}
                options={getChartConfig(reportType, report)}
            />
        </ChartContainer>
    </Container>);
}

export default ManageAIController.withState(
    ReportByModels
);
