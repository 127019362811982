import React, { Component } from "reactn";
import PropTypes from "prop-types";
import { withStyles, withTheme } from "@material-ui/core/styles";

import { pageStyles } from "../styles/page_styles";
import TableReport from "../components/TableReport";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

class Edu extends Component {
    getEduUsers = async () => {
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/eduUsers", {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`
            }
        });
        if (!response.ok) {
            return [
                { id: "Error " + response.status, email: "" }
            ];
        }
        return await response.json();
    }

    deleteEduUser = async userId => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/eduUsers", {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userId })
        });
        if (!response.ok) {
            handleShowDialog(
                "Delete edu plan failed",
                `Error: ${response.status} ${response.statusText}`
            );
            return null;
        }
        window.location.reload();
    };

    validateEduUser = async userId => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/validateEduUser", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userId })
        });
        if (!response.ok) {
            handleShowDialog(
                "Validate edu plan failed",
                `Error: ${response.status} ${response.statusText}`
            );
            return null;
        }
        window.location.reload();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.page}>
                <h2 className={classes.pageTitle}>Edu Users</h2>
                <div className={classes.blockGrid}>
                    <TableReport
                        handleShowDialog={this.props.handleShowDialog}
                        fetchOnMount={this.getEduUsers}
                        bodyParamName="userEmailOrUid"
                        requestURL="/admin/eduUsers"
                        columns={[

                            {
                                Header: "User Id",
                                accessor: "id"
                            },
                            {
                                Header: "Email",
                                accessor: "email"
                            },
                            {
                                Header: "School Name",
                                accessor: "schoolName"
                            },
                            {
                                Header: "School Url",
                                accessor: "schoolUrl"
                            },
                            {
                                Header: "School Grade",
                                accessor: "schoolGrade"
                            },
                            {
                                Header: "Start Date",
                                accessor: "eduStartDate",
                                Cell: row => {
                                    return new Date(row.value).toDateString();
                                }
                            },
                            {
                                Header: "Verified",
                                accessor: "verified",
                                Cell: row => {
                                    if (row.value) {
                                        return (<div style={{ color: "green" }}>
                                            true
                                        </div>);
                                    } else {
                                        return (<div style={{ color: "orangered" }}>
                                            false
                                        </div>);
                                    }
                                }
                            },
                            {
                                Cell: props => (
                                    <div>
                                        <button style={{ backgroundColor: "green", marginRight: 10 }} onClick={() => this.validateEduUser(props.value.id)}>
                                            Validate
                                        </button>
                                        <button onClick={() => this.deleteEduUser(props.value.id)}>
                                            Reject
                                        </button>
                                    </div>
                                ),
                                Header: "",
                                accessor: "",
                                filterable: false
                            }
                        ]}
                        tableProps={{
                            filterable: true
                        }}
                        description="List of current education plan users."
                        title="All EduUsers"
                        buttonLabel="Add EduUser"
                        placeholder="User ID or Email"
                    />
                </div>
            </div>
        );
    }
}

Edu.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withFirebaseUser((withTheme(withStyles(pageStyles)(Edu))));
